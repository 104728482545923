import React, { useState, useEffect, useCallback } from 'react';

export function useWindowHeight() {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowHeight;
}

export function useLoadingDots() {
    const [ellipsis, setEllipsis] = useState("");
    const maxDots = 3;
    const loadingText = "Loading";
    const [isInterval, setIsInterval] = useState(false);

    useEffect(() => {
        let interval;
        if (isInterval) {
            interval = setInterval(() => {
                // console.log('интервал работает');

                setEllipsis((prev) => {
                    // If the number of dots reaches the max, reset to empty
                    return prev.length < maxDots ? prev + "." : "";
                });
            }, 500)
        }

        return () => {
            clearInterval(interval);
            setEllipsis("");
        }

    }, [isInterval])

    const onSetInterval = () => {
        setIsInterval(true)
    }

    const onClearInterval = () => {
        setIsInterval(false)
    }


    return { loadingText: `${loadingText}${ellipsis}`, onSetInterval, onClearInterval }
}