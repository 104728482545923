import { track } from "@vercel/analytics/react";
import React, { useEffect, useState } from "react";
import Gravatar, { displayName } from "react-gravatar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import styles from './index.module.scss';

import { StripeAPI } from "@/api/stripe";
import { UserAPI } from "@/api/user";
import shield from '@/assets/icons/shield.svg';
import { Col, Row, Layout, Button, Input, MobileLayout, Layout as MainLayout } from "@/components/Layout";
import MobileTopBar from "@/components/mobileTopBar/mobileTopBar";
import { useDesktop, useDesktopMini, useMobileMini } from "@/hooks/useAdaptives";
import { selectUserData } from '@/store/user/selectors';
import { initializeUser } from "@/store/user/slice";



const DOCS_PRIVACY_URL = process.env.REACT_APP_DOCS_PRIVACY_URL;
const DOCS_TERMS_URL = process.env.REACT_APP_DOCS_TERMS_URL;

export const Profile = () => {
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();

    const [user, setUser] = useState({
        firstname: useSelector(selectUserData).first_name,
        lastname: useSelector(selectUserData).last_name,
        email: useSelector(selectUserData).username,
    })

    const navigate = useNavigate();

    const [enabled, setEnabled] = useState(false);

    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        await UserAPI.setProfile(user.email, user.firstname, user.lastname);
        dispatch(initializeUser());
        toast.success("Changes have been saved");
        track("Profile changed");
    }

    const handleChange = (e, event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    }

    const isMobileMini = useMobileMini();

    const Layout = !isDesktop && !isDesktopMini ? MobileLayout : MainLayout;

    const [subscription, setSubscription] = useState(null);

    useEffect(() => {
        StripeAPI.checkPayment().then(res => {
            setSubscription(res)
        })
    }, [])

    return (
        <Layout mainAddClass={!isDesktop && !isDesktopMini ? styles.mobile_main : ''}>
            {!isDesktop && !isDesktopMini ? <MobileTopBar title={''} /> : null}
            <Col className={'w-100 justify-content-center align-items-center px-2 px-md-5 py-2'}>
                <div className={`${styles.card} card mx-auto w-100 align-items-center`}>
                    <div className="card-body w-75">
                        <Gravatar email={user.email}
                            className={`rounded-circle ${isMobileMini ? styles.profileImage_small : styles.profileImage_large}`} />
                        {subscription && subscription?.purchases.length > 0 ? <div className={styles.subscription}>
                            <span style={{ fontWeight: '500' }}>Recent Purchase Made: </span> {subscription?.purchases[0]?.product_name}
                        </div> : null}
                        <div className={styles.data_card}>
                            <form onSubmit={handleSubmit}>
                                <div className={styles.profile_data}>
                                    <label>First Name</label>
                                    <Input className={styles.input} type="text" value={user.firstname} name="firstname" onChange={handleChange} />
                                </div>
                                <div className={styles.profile_data}>
                                    <label>Last Name</label>
                                    <Input className={styles.input} type="text" value={user.lastname} name="lastname" onChange={handleChange} />
                                </div>
                                <div className={styles.profile_data}>
                                    <label>Email Address</label>
                                    <Input className={styles.input} type="text" value={user.email} name="email" onChange={handleChange} />
                                </div>
                                <Button type="submit" className={'mx-auto my-0'} style={{ fontSize: '1rem' }}>Save Profile</Button>
                            </form>
                        </div>
                    </div>
                </div>
                <Row style={!isDesktop && !isDesktopMini ? {} : { display: 'none' }}>
                    <Button className={'mt-5 me-3'} onClick={() => { navigate('/password') }}>Change Password</Button>
                    <Button className={'mt-5'} style={{ backgroundColor: '#E22030', borderColor: '#E22030' }} onClick={() => { navigate('/logout') }}>Sign Out</Button>
                </Row>
            </Col>
        </Layout >
    )
}