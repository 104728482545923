import { track } from "@vercel/analytics/react";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from './CertainRoomPage.module.scss';

import { ReportAPI } from "@/api/report";
import master_bedroom from '@/assets/rooms/master_bedroom.png';
import photos_icon from "@/assets/rooms/photos_icon.png";
import Card, { CardLinkPosition, CardType } from "@/components/Card";
import CustomMuiDialogWindow from "@/components/customMuiDialogWindow/customMuiDialogWindow";
import { Contents, Icon, MobileLayout } from "@/components/Layout";
import MobileTopBar from "@/components/mobileTopBar/mobileTopBar";
import { useDesktop, useDesktopMini, useMobileMini } from "@/hooks/useAdaptives";
import { dispatch } from "@/store";
import { rooms_features } from "@/store/constants";
import { chooseRoom } from "@/store/rooms/roomsSlice";
import { selectUserData } from "@/store/user/selectors";
import { checkPosition } from "@/utils";
import BigDisplayBlock from "@/views/PropertyInsights/issueComponents/bigDisplayBlock/bigDisplayBlock";
import commonStyles from "@/views/PropertyInsights/Issues.module.scss";

const HighlightedText = ({ text, wordsToHighlight }) => {
    const highlightedText = text.split(' ').map((word, index) => {
        const cleanWord = word.replace(/[.,!?;:()]/g, '');
        if (wordsToHighlight.includes(cleanWord.toLowerCase())) {
            return <span key={index} className={word.toLowerCase() === 'pool' ? styles.highlight_pool
                : cleanWord.toLowerCase() === 'patio' ? styles.highlight_patio : ''}>{word} </span>;
        }
        return word + ' ';
    });

    return <p>{highlightedText}</p>;
};

HighlightedText.propTypes = {
    text: PropTypes.string,
    wordsToHighlight: PropTypes.arrayOf(PropTypes.string),
}
const CertainRoomPage = () => {
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();
    const { chosenReport } = useSelector(state => state.reports);
    const { chosenRoom, roomsImages, photos } = useSelector(state => state.rooms);

    const exteriorValues = [
        "Roofing",
        "Siding",
        "Lot Size Openness",
        "Amenities",
        "Architectural Style",
        "Property Exterior Quality",
        "Accessibility"
    ]

    const [features, setFeatures] = useState(chosenRoom?.features)

    // let features = chosenRoom?.features;

    useEffect(() => {
        if (chosenRoom?.room_type === 'Exterior') {
            const check = (item) => {
                for (let i = 0; i < exteriorValues.length; i++) {
                    if (exteriorValues[i] === item) {
                        return true;
                    }
                }
                return false;
            }
            const copy = features?.filter(item => check(item.feature))
            setFeatures(copy);
        }
    }, [])

    const [chatTaskId, setChatTaskId] = useState("");
    const [isWaitingForBotResponse, setIsWaitingForBotResponse] = useState(false);

    const [chat, setChat] = useState(false);
    const [chatId, setChatId] = useState(0);
    const initialBotMessage = "Hey there! I am the HomeScore Assistant! I'm here to help answer your questions ";
    const [chatMessages, setChatMessages] = useState([{
        content: initialBotMessage + `about 'Room Size'. How can I help?`,
        message_type: "AI",
        issue: 'Room Size'
    }]);
    const [isLoading, setIsLoading] = useState(false)
    const [loading, setLoading] = useState('.');
    const [message, setMessage] = useState('');
    const user = useSelector(selectUserData);
    const chatContainerRef = useRef(null);
    const isMobileMini = useMobileMini();
    const navigate = useNavigate();

    const [hover, setHover] = useState({
        id: -1,
        isHovered: false
    });

    const [isShowBig, setIsShowBig] = useState(false);
    const [chosenFeature, setChosenFeature] = useState(null);

    const chooseFeature = async (feature) => {
        setIsShowBig(true);
        setChosenFeature(feature);

        await ReportAPI.fetchOrCreateChat(chosenReport?.id, feature?.feature).then(data => {
            const emptyChatMessages = [{
                content: initialBotMessage + `about ${feature?.feature}. How can I help?`,
                message_type: "AI",
                issue: feature?.feature
            }]
            setChatId(data.id);
            data?.messages?.length > 0 ? setChatMessages(data.messages) : setChatMessages(emptyChatMessages)
        })
    }

    const handleMessage = (e, event) => {
        setMessage(event.target.value);
    }

    const handleMessageSend = async () => {
        if (message !== "" || message !== null || message !== undefined) {
            const newUserMessage = { content: message, message_type: "HUMAN", issue: chosenFeature?.feature, user: user.id };
            setChatMessages([...chatMessages, newUserMessage]);
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            await ReportAPI.sendMessage(message, chatId, chosenFeature?.feature).then(data => {
                setChatTaskId(data.task_id)
                setMessage("")
                setIsWaitingForBotResponse(true);
            })
            track("Sent Chat", { location: 'rooms', issue_type: chosenFeature?.feature });
        }
    }

    async function handleKeyDown(event) {
        if (event.key === 'Enter') {
            const newUserMessage = { content: message, message_type: "HUMAN", issue: chosenFeature?.feature, user: user.id };
            setChatMessages([...chatMessages, newUserMessage]);

            await ReportAPI.sendMessage(message, chatId, chosenFeature?.feature).then(data => {
                setChatTaskId(data.task_id)
                setMessage("")
                setIsWaitingForBotResponse(true);
            })
            track("Sent Chat", { location: 'rooms', issue_type: chosenFeature?.feature });
        }
    }

    useEffect(() => {
        let interval;
        if (isWaitingForBotResponse) {
            interval = setInterval(() => {
                setLoading((prevLoad) => {
                    // If there are already 3 dots, reset to one dot.
                    return prevLoad === '...' ? '.' : prevLoad + '.';
                });
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                ReportAPI.checkBotResponse(chatTaskId, chatId).then(data => {
                    if (data.state === "SUCCESS" || data.state === "FAIL") {
                        const newBotMessage = { content: data.result.content, message_type: "AI", issue: chosenFeature?.feature };
                        setChatMessages([...chatMessages, newBotMessage]);
                        setIsWaitingForBotResponse(false);
                        clearInterval(interval);
                    }
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isWaitingForBotResponse, chatTaskId, chatMessages, chosenFeature]);

    //reset chosenRoom when unmounting
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        return () => {
            dispatch(chooseRoom(null))
        }
    }, [chosenRoom])

    //photos for swiper
    const [yourPhotos, setYourPhotos] = useState(roomsImages);

    useEffect(() => {
        setYourPhotos(prev => [...photos, ...prev])
    }, [photos])

    //custom styles for modal window with large fotos swiper
    const dialogStyles = {
        backgroundColor: 'inherit',
        width: '100%',
        maxWidth: '500px',
        margin: '10px 3px',
        border: 'none',
        boxShadow: "none",
    }

    const dialogContentStyles = {
        textAlign: 'center',
        padding: '0'
    }

    //open modal window with bigger photos from swiper
    const [open, setOpen] = useState(false);
    //for open large swiper with active photo whit user clicked on
    const [chosenPhoto, setChosenPhoto] = useState(0);

    //open modal window with bil slider and save chosen photo for active slide
    const handleClickOpen = (photoIndex) => {
        setChosenPhoto(photoIndex)
        setOpen(true);
    };

    //to close modal window with big swiper
    const handleClose = () => {
        setOpen(false);
        setChosenPhoto(0)
    };

    const goBack = () => {
        if (isShowBig) {
            chat && setChat(false);
            setIsShowBig(false);
        } else {
            navigate(-1)
        }
    }

    //for appearing arrow link
    const [activeCardIndex, setActiveCardIndex] = useState(null);
    const containerRef = useRef(null);

    // function from utils
    const checkElementPositions = useCallback(() => checkPosition(containerRef, activeCardIndex, setActiveCardIndex), [activeCardIndex]);

    useEffect(() => {
        if (isMobileMini) {
            window.addEventListener("scroll", checkElementPositions);

            return () => {
                window.removeEventListener("scroll", checkElementPositions);
            };
        }
    }, [features, isMobileMini]);

    function formatRoomType(roomType) {
        if (roomType === null || roomType === undefined) {
            return ''
        }

        return roomType
            .split('_') // Split by underscores
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
            .join(' '); // Join them back with spaces
    }

    return (
        isDesktop || isDesktopMini || !chosenRoom ? <Navigate to={'/'} />
            : <MobileLayout mainAddClass={!isDesktop && !isDesktopMini ? styles.mobile_main : ''}>
                <CustomMuiDialogWindow open={open}
                    onClose={handleClose}
                    dialogStyles={dialogStyles}
                    dialogContentStyles={dialogContentStyles}>
                    <Swiper autoHeight={true}
                        id={'large_photos'}
                        initialSlide={chosenPhoto}
                        spaceBetween={10}
                        speed={600}
                        slidesPerView={1}
                        slidesPerGroup={1}
                        modules={[Pagination]}
                        pagination={{
                            clickable: true,
                            el: '#test_pagination',
                            bulletClass: styles.pagination_bullet,
                            bulletActiveClass: styles.pagination_bullet_active,
                        }}>
                        {yourPhotos?.filter(image => image?.room_type_str === chosenRoom?.room_type).map((photo, index) => (
                            <SwiperSlide key={photo.id}>
                                <img src={photo.image_url} alt="" style={{
                                    width: '100%',
                                    maxHeight: '80vh',
                                    objectFit: 'contain',
                                    borderRadius: '24px'
                                }} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className={styles.pagination} id={'test_pagination'}></div>
                </CustomMuiDialogWindow>

                <MobileTopBar title={chosenRoom?.room_type || ''} goBack={goBack} />

                <Contents>
                    <div className={styles.certain_room}>
                        {
                            isShowBig ?
                                <div className={`${styles.container} mb-3`}>
                                    {/*block with chat for feature {chosenFeature.title}*/}
                                    <BigDisplayBlock>
                                        <BigDisplayBlock.Header icon={rooms_features[chosenFeature?.feature]?.image}
                                            className={`${commonStyles.box} ${chat ? commonStyles.smallBox1 : commonStyles.box1} ${commonStyles.general}`}
                                            chat={chat}>
                                            <>
                                                {rooms_features[chosenFeature?.feature]?.icon}
                                                <h2 style={{ textAlign: 'left' }}>{chosenFeature?.feature}</h2>
                                            </>
                                        </BigDisplayBlock.Header>
                                        <BigDisplayBlock.Body chat={chat}
                                            className={`${commonStyles.box} ${chat ? commonStyles.smallBox2 : commonStyles.box2}`}
                                            setChat={setChat}
                                            message={message}
                                            handleMessage={handleMessage}
                                            handleKeyDown={handleKeyDown}
                                            isMobileMini={isMobileMini}
                                            chatContainerRef={chatContainerRef}
                                            chatMessages={chatMessages}
                                            isWaitingForBotResponse={isWaitingForBotResponse}
                                            loading={loading}
                                            handleMessageSend={handleMessageSend}
                                            withoutBodyAnimation={true}>
                                            {isLoading ?
                                                <span className={commonStyles.customLoader} />
                                                : <div className={styles.features_block}>
                                                    {
                                                        <div
                                                            className={styles.features_block}
                                                            style={{ flexDirection: 'column', justifyContent: 'flex-start' }}
                                                        >
                                                            <h1 style={{ textAlign: 'start' }}>
                                                                Description
                                                            </h1>
                                                            <p>{chosenFeature.summary}</p>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </BigDisplayBlock.Body>
                                    </BigDisplayBlock>

                                </div>
                                :
                                <>
                                    <div className={`${styles.card} mx-auto w-100 mw-100`}
                                        style={{ height: '200px!important', minHeight: '200px' }}>
                                        <div
                                            className={`${styles.card_header} w-100 d-flex align-items-center gap-2 mb-2 mt-2`}>
                                            <div className={styles.icon_wrapper}>
                                                <Icon src={photos_icon} />
                                            </div>
                                            <p>Your Photos</p>
                                        </div>
                                        <Swiper autoHeight={true} id={'photos'}
                                            spaceBetween={10}
                                            speed={600}
                                            slidesPerView={"auto"}
                                            slidesPerGroup={1}
                                            className={'mt-3 mb-5'}
                                            style={{ margin: '0 5px' }}
                                        >
                                            {yourPhotos?.filter(image => image?.room_type_str === chosenRoom?.room_type).map((photo, index) => (
                                                <SwiperSlide key={photo.id}>
                                                    <div key={index} className={styles.photo}
                                                        onClick={() => handleClickOpen(index)}>
                                                        <img src={photo.image_url} alt="" />
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>

                                    <div className={styles.cards_wrapper} ref={containerRef}>
                                        {
                                            features.map((f, index) => {
                                                return (
                                                    <Card
                                                        key={index}
                                                        className={`${styles.card} observed-element`}
                                                        data-id={index}
                                                        bgClass={styles.test}
                                                        onClick={() => {
                                                            chooseFeature(f);
                                                        }}
                                                        type={CardType.smallBox}
                                                        linkPosition={CardLinkPosition.topRight}
                                                        action={() => {
                                                            chooseFeature(f);
                                                        }}
                                                        hoveredClass={isMobileMini && activeCardIndex === index}
                                                        onMouseEnter={() => {
                                                            setHover({
                                                                id: index,
                                                                isHovered: true
                                                            })
                                                        }}
                                                        onMouseLeave={() => {
                                                            setHover({
                                                                id: -1,
                                                                isHovered: false
                                                            })
                                                        }}
                                                    >
                                                        <Card.Header
                                                            className={`${styles.card_header}  w-100 d-flex align-items-center gap-2 py-0 mb-2`}
                                                        >
                                                            <div className={styles.icon_wrapper}>{rooms_features[f?.feature]?.icon}</div>

                                                            <p>
                                                                {((hover.isHovered && index === hover.id) || (activeCardIndex === index)) && f?.feature?.length >= 16 ? f?.feature.slice(0, 14) + '...' : f?.feature}
                                                            </p>
                                                        </Card.Header>
                                                        <Card.Body
                                                            className={styles.card_body}
                                                        >
                                                            {
                                                                f.values.length > 0
                                                                    ?
                                                                    <div
                                                                        className={styles.value_wrapper}
                                                                        style={{
                                                                            color: 'white',
                                                                            backgroundColor: rooms_features[f.feature]?.bg || '#5F5F5F'
                                                                        }}>
                                                                        {
                                                                            (f.values.length > 1 && (f.values[0] === "none" || f.values[0] === "None")) ?
                                                                                formatRoomType(f.values[1])
                                                                                :
                                                                                formatRoomType(f.values[0])
                                                                        }
                                                                    </div>
                                                                    :
                                                                    null
                                                            }

                                                            {
                                                                f.summary ?
                                                                    <div
                                                                        className={styles.description_wrapper}
                                                                        style={{ maxHeight: '65px', overflowY: 'auto' }}
                                                                    >
                                                                        {
                                                                            f.summary
                                                                        }
                                                                    </div> : null
                                                            }

                                                            {
                                                                <div className={styles.ranks_with_img_wrapper} style={{ justifyContent: 'flex-end' }}>
                                                                    <div className={styles.ranks_image_wrapper}
                                                                        style={{ display: 'block' }}>
                                                                        <img src={rooms_features[f.feature]?.image ? rooms_features[f.feature]?.image : master_bedroom} />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Card.Body>
                                                    </Card>
                                                )
                                            })
                                        }
                                    </div>
                                </>}
                    </div>
                </Contents>
            </MobileLayout>
    )
}

export default CertainRoomPage;