import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { UsersThree, XCircle } from '@phosphor-icons/react';
import { track } from '@vercel/analytics/react';
import React, { useEffect, useState } from 'react';
import Gravatar from "react-gravatar";
import { useSelector } from "react-redux";
import { useParams, useLocation, Link, useNavigate, Navigate } from 'react-router-dom';

import styles from './index.module.scss';
import * as issuesContents from './Issues.jsx';
import * as mainContents from './Main';
import * as roomContents from './rooms/mainScreenRooms/MainScrrenRooms';

import { ReportAPI } from '@/api/report';
import fillerHouse from '@/assets/fillerHouse.png';
import homeImg from '@/assets/home.svg';
import addBlack from '@/assets/icons/addBlack.svg';
import addWhite from '@/assets/icons/addWhite.svg';
import angleArrowDown from '@/assets/icons/angleArrowDown.svg';
import angleArrowUp from '@/assets/icons/angleArrowUp.svg';
import deleteRed from '@/assets/icons/deleteRed.svg';
import upload_docs from "@/assets/icons/upload_docs.png";
import { UploadModal } from "@/components/HouseCard/UploadModal";
import {
    Layout,
    Contents,
    Row,
    Col,
    Button,
    Img,
    Icon,
    Input,
    Modal,
    MobileLayout,
    Layout as MainLayout
} from '@/components/Layout';
import MobileMenu from "@/components/mobileMenu/mobileMenu";
import MobileTopBar from "@/components/mobileTopBar/mobileTopBar";
import { useDesktop, useDesktopMini, useMobileMini } from "@/hooks/useAdaptives";
import { dispatch } from "@/store";
import { chooseReport } from "@/store/reports/reportsSlice";
import { getReportAddresses } from '@/utils';

const DOCS_PRIVACY_URL = process.env.REACT_APP_DOCS_PRIVACY_URL;
const DOCS_TERMS_URL = process.env.REACT_APP_DOCS_TERMS_URL;

const Tabs = {
    yourInsights: {
        key: 'your-insights',
        name: 'Your Insights',
    },
    problemAreas: {
        key: 'problem-areas',
        name: 'Problem Areas',
    },
    byRoom: {
        key: 'by-room',
        name: 'By Room',
    },
};

const MobileTabs = {
    yourInsights: {
        key: 'your-insights',
        name: 'Your Insights',
    },
    problemAreas: {
        key: 'problem-areas',
        name: 'Problem Areas',
    },
};

export const PropertyInsights = () => {
    const defaultTab = Tabs.yourInsights;
    const { chosenReport } = useSelector(state => state.reports);
    const navigate = useNavigate();
    const isMobileMini = useMobileMini();
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();
    const { hash } = useLocation();
    const { propertyId } = useParams();
    // const [report, setReport] = useState(null);
    const [reports, setReports] = useState(null);
    const [currentTab, setCurrentTab] = useState(defaultTab.key);
    const [tabState, setTabState] = useState({});
    const [dropdown, setDropdown] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [collabrators, setCollabrators] = useState([]);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [modelLoading, setModelLoading] = useState(false);
    const [uploadOpen, setUploadOpen] = useState(false);

    const CurrentTabs = (!isDesktop && !isDesktopMini) ? MobileTabs : Tabs;

    const tabsComponents = (!isDesktop && !isDesktopMini)
        ? {
            [Tabs.yourInsights.key]: mainContents,
            [Tabs.problemAreas.key]: issuesContents,
        }
        : {
            [Tabs.yourInsights.key]: mainContents,
            [Tabs.problemAreas.key]: issuesContents,
            [Tabs.byRoom.key]: roomContents,
        };


    const tabComponents = tabsComponents[currentTab] || tabsComponents[defaultTab.key];
    const TabContents = tabComponents.Body;
    const TabControls = tabComponents.Controls;


    const { address2, address1 } = getReportAddresses(chosenReport);
    const [invitingCol, setInvitingCol] = useState('');

    const onClose = () => {
        setIsModal(false);
    };

    const onOpen = () => {
        setModelLoading(true);
        ReportAPI.getCollabrators(propertyId).then(data => {
            setCollabrators(data);
            setModelLoading(false);
        }).catch(data => {
            setModelLoading(false)
        });
        setIsModal(true);
        track("Collaborators");
    };

    const sendingInvite = (event) => {
        event.preventDefault();
        setModelLoading(true);
        ReportAPI.inviteCollabrators(propertyId, invitingCol).then(data => {
            if (data && data.collaborator) {
                const col = [...collabrators];
                col.unshift({ collaborator: data.collaborator });
                setCollabrators(col);
            }
            setInvitingCol('');
            setModelLoading(false);
            track("Adding Collaborators")
        }).catch(data => {
            setModelLoading(false)
        });
    }

    const sendingInviteChange = (e, event) => {
        setInvitingCol(event.target.value);
        setIsValidEmail(validateEmail(event.target.value));
    }

    const validateEmail = (email) => {
        // Basic email validation using a regular expression
        // You can use a more sophisticated validation method if needed
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    useEffect(() => {
        ReportAPI.getReport(+propertyId).then(data => {
            dispatch(chooseReport(data))
        });

        return () => {
            (isDesktop || isDesktopMini) && dispatch(chooseReport(null))
        }
    }, [propertyId, isDesktop, isDesktopMini]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        ReportAPI.getReports().then(data => {
            setReports(data);
        });
    }, []);

    useEffect(() => {
        setCurrentTab(hash.replace('#', '') || defaultTab.key);
        setTabState({});
    }, [hash]);

    useEffect(() => {
        if (!isDesktop && !isDesktopMini) {
            navigate(' ')
        }
    }, [isDesktop, isDesktopMini])


    const Layout = !isDesktop && !isDesktopMini ? MobileLayout : MainLayout;

    return (
        <Layout mainAddClass={!isDesktop && !isDesktopMini ? styles.mobile_main : ''}>
            {/*{!isDesktop && !isDesktopMini ? <MobileTopBar title={chosenReport?.name || ''} link={'/property-organization'}/> : null}*/}
            <Contents className={'d-flex flex-column pb-4 mx-auto'}>
                {
                    !isDesktop && !isDesktopMini
                        ? null
                        : <>
                            <Row style={{ marginTop: '2.5rem' }}>My
                                Properties &gt; {chosenReport?.name === "Sample Report" ? "Sample Report" : (address2 || '...')}</Row>
                            <Row className={'mt-4 justify-content-center justify-content-sm-start'}>
                                <Col>
                                    <h1 className={`${styles.title} me-3`}>{address2 || '...'}</h1>
                                    <h2 className={styles.subtitle}>{address1 || '...'}</h2>
                                </Col>
                            </Row>
                        </>
                }

                {chosenReport && chosenReport?.details_status === 'Done' ? <Row className={`gap-1 mt-3 mb-4 justify-content-start flex-nowrap`}>
                    <Col>
                        <Row className={`d-flex gap-2 justify-content-center flex-nowrap flex-shrink-0`}>
                            <div className={`${styles.tabs} d-flex me-0 me-sm-2 rounded-pill`}>
                                {Object.values(CurrentTabs).map(({ key, name }) => (
                                    <Link
                                        key={key}
                                        className={styles.tab + ' d-flex position-relative justify-content-center- align-items-center gap-2 py-3 px-4 rounded-pill ' + (currentTab === key ? styles.current : '')}
                                        to={'#' + key}
                                        data-text={name}
                                    >
                                        {name}
                                    </Link>
                                ))}
                            </div>
                            <TabControls
                                propertyId={propertyId}
                                report={chosenReport}
                                isDesktop={isDesktop}
                                isDesktopMini={isDesktopMini}
                                uploadOpen={uploadOpen}
                                setUploadOpen={setUploadOpen}
                                setState={setTabState}
                                state={tabState}
                            />
                        </Row>
                    </Col>

                    <Col className={'ms-0 ms-xl-auto'}>
                        <Button onClick={onOpen}>
                            {!isMobileMini ? 'Add Collaborators' : ''}
                            <UsersThree size={22} />
                        </Button>
                    </Col>

                    {!isDesktop && !isDesktopMini
                        ? null
                        : <Col
                            className={`${styles.dropdownHeader} position-relative cursor-pointer rounded-pill bg-white py-2 px-3`}
                            onClick={e => (e.preventDefault(), setDropdown(!dropdown))}>
                            <Row
                                className={`justify-content-between align-items-center w-100 ${styles.dropdownHeader_content}`}>
                                <p>{chosenReport?.name === "Sample Report" ? "Sample Report" : address2}</p>
                                <Img src={!dropdown ? angleArrowDown : angleArrowUp} />
                            </Row>

                            {dropdown && (
                                <div className={styles.dropdown}>
                                    <p className={'mt-1'}>Choose a house to view*</p>
                                    <div className={styles.dropdown_content}>
                                        {reports.map((report, index) => (
                                            <Row className={styles.dropdown_item}
                                                key={index}
                                                onClick={() => {
                                                    setDropdown(!dropdown)
                                                    navigate(`/my-properties/insights/${report?.id}`)
                                                }}
                                            >
                                                <Img className={styles.dropdown_item_img}
                                                    src={report.details.imgSrc || fillerHouse}
                                                    preloadSrc={fillerHouse}
                                                />
                                                <Col className={styles.dropdown_item_content}>
                                                    <p>
                                                        {report?.name === "Sample Report" ? "Sample Report" : getReportAddresses(report).address2}
                                                        <br />
                                                        {report?.name === "Sample Report" ? "" : getReportAddresses(report).address1}
                                                    </p>
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </Col>}
                </Row> : null
                }
                {
                    !chosenReport || (chosenReport?.details_status !== 'Done' && chosenReport?.details_status !== 'Error') ?
                        <>
                            <h1 className={`my-4`}>
                                Loading insights...
                            </h1>
                            <div>
                                <div className="spinner-border text-dark"
                                    style={{ width: '3rem', height: '3rem' }}
                                    role="status"></div>
                            </div>
                        </>
                        :
                        <TabContents propertyId={propertyId} report={chosenReport} state={tabState} />
                }
                <Row className={`${styles.footer} mt-auto py-3 w-100 gap-1 justify-content-center border-top py-2`}>
                    <span>©2024 Homescore&nbsp;</span> | <Link to={DOCS_TERMS_URL}>&nbsp;Terms of
                        Service&nbsp;</Link> |
                    <Link to={DOCS_PRIVACY_URL}>&nbsp;Privacy Policy</Link>
                </Row>
            </Contents>

            <UploadModal report={chosenReport} isOpen={uploadOpen}
                onClose={() => setUploadOpen(false)} />

            <Modal isOpen={isModal} onClose={onClose} title="">
                <Backdrop
                    sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={modelLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Col className={`${styles.content_container} w-100 align-items-center`}>
                    <Img src={homeImg} className={styles.image} />
                    <h1 className={styles.collab_title}>
                        It&apos;s better when you search for home together
                    </h1>
                    <p className={styles.collab_subtitle}>
                        Team up with your spouse, partner, or friend to shop for homes together,
                        share insights, and get answers to your questions using HomeScore AI.
                    </p>
                    <Row className={`${styles.parentBox} flex-nowrap rounded-pill p-1`}>
                        <Input
                            className={`py-2 px-4 w-100 ${styles.input}`}
                            placeholder="Email address"
                            type="email"
                            required={true}
                            value={invitingCol}
                            onChange={sendingInviteChange}
                        />
                        <Button disabled={!isValidEmail || !invitingCol.length} onClick={sendingInvite}
                            className={`${styles.sendInvite} px-4 border-0`}>Send Invite</Button>
                    </Row>
                    <Col className={`${styles.collaborators} w-100`}>
                        <h6 className={'mb-3'}>Collaborators</h6>
                        <div className={`${styles.collab_container} w-100 p-0 overflow-auto`}>
                            {collabrators.length > 0 ? collabrators.map((_, r) => (
                                <Row className={`w-100 flex-nowrap justify-content-start gap-2 py-2 px-0 ${collabrators.length - 1 !== r ? styles.collaborator_item_border : ''}`}
                                    key={r}>
                                    <Gravatar email={_.collaborator} className={`p-0 flex-shrink-0 rounded-pill`}
                                        style={{ width: '2.625rem', height: '2.65rem' }} />
                                    <h3 className={'text-break mb-0'} style={{ fontSize: '1rem' }}>{_.collaborator}</h3>
                                </Row>
                            )) : <h2 className={'mt-4'}>No Collaborators</h2>}
                        </div>
                    </Col>
                </Col>
            </Modal>
        </Layout >
    );
};
