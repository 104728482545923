import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import styles from './mobileTopBar.module.scss';

import { StripeAPI } from "@/api/stripe";
import logo from "@/assets/headerLogo.svg";
import bell from "@/assets/rooms/bell.png";
import icon_back from "@/assets/rooms/icon_back.png";
import { Button, Icon, Img } from "@/components/Layout";
import { dispatch } from "@/store";
import { getSubInfo } from "@/store/stripe/stripeSlice";


const MobileTopBar = ({ title, link, goBack, ...props }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const goBackAction = () => {
        if (goBack) {
            goBack();
            return
        }
        link ? navigate(link) : navigate(-1);
    }

    const [subscription, setSubscription] = useState(null);

    // useEffect(() => {
    //     StripeAPI.checkPayment().then(res => {
    //         setSubscription(res)
    //     })
    // }, [])

    const { remaining } = useSelector((state) => state.stripe);

    useEffect(() => {
        dispatch(getSubInfo());
    }, [dispatch]); // Dispatch only needs to be run once on component mount

    // Log `remaining` whenever it changes
    useEffect(() => {
        console.log('In Mobile Header Remaining:', remaining);
    }, [remaining]);

    const subscribe = () => {
        const preProcess = {
            remaining: remaining
        }
        localStorage.setItem('pre-process', JSON.stringify(preProcess))
        navigate('/subscriptions')
    }

    return (
        <div className={styles.room_menu} {...props}>
            {location?.pathname !== '/my-properties' ? <div className={styles.notification_button} onClick={goBackAction}>
                <Icon src={icon_back} style={{ width: '10px', minWidth: '10px', height: '17px' }}
                    className={`flex-shrink-0`} />
            </div> : null}
            {
                ['/my-properties', '/comparison', '/profile'].includes(location?.pathname) ?
                    <div className={'d-flex me-auto'}>
                        <Img className={`${location?.pathname === '/my-properties' ? '' : 'ms-3'}`} style={{ height: '1.75rem' }} src={logo} />
                        {remaining === 'Unlimited' ?
                            <div
                                className={`${styles.upgrade_btn} ms-3`}
                                style={{ background: 'black', color: 'white', borderRadius: '10px', alignContent: 'center' }}
                            >
                                Unlimited Access
                            </div>
                            :
                            <Button className={`${styles.upgrade_btn} ms-3`} onClick={() => { subscribe() }}>
                                {location?.pathname === '/my-properties' && remaining > 0 ? `Add ${remaining} more home${remaining === 1 ? '' : 's'}` : 'Upgrade'}
                            </Button>
                        }
                    </div>
                    : null
            }
            <div className={`${styles.title} mx-auto`}>{title}</div>
            {/*<div className={styles.notification_button}>*/}
            {/*    <Icon src={bell} style={{width: '17px', minWidth: '17px', height: '18px'}}*/}
            {/*          className={`flex-shrink-0`}/>*/}
            {/*    /!*<div className={styles.red_point}></div>*!/*/}
            {/*</div>*/}
        </div>
    )
}

MobileTopBar.propTypes = {
    title: PropTypes.string,
    link: PropTypes.string,
    goBack: PropTypes.func
}
export default MobileTopBar;