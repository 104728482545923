import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import styles from './index.module.scss';

import { Authentication } from "@/components/Authentication";
import { Row } from "@/components/Layout";
import { selectLoading } from "@/store/user/selectors";
import { signupUser } from '@/store/user/slice';
import { trackEvent } from "@/utils";


export const Signup = () => {
    const dispatch = useDispatch();

    const loading = useSelector(selectLoading);
    const signup = async (name, email, password) => {
        // trackEvent("sign_up", "Form", "Sign Up");
        dispatch(signupUser({ username: name, email, password1: password, password2: password }));
    }

    return (
        <Authentication subtitle="Get Started!" buttonType="Sign Up" onClick={signup} loading={loading} seeGoogle={true}>
            <Row className={`${styles.links_wrapper} flex-nowrap justify-content-center`}>
                <Link to="/login" className={'text-center text-decoration-underline'}>
                    Have an account?
                </Link>
            </Row>
        </Authentication>
    )
}