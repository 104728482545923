import PropTypes from 'prop-types';

const numFormatter = new Intl.NumberFormat();
export const formatNumber = number => {
    return numFormatter.format(number);
};

export const wrapper = fn => {
    fn.propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
    };
    return fn;
};

export const firstOf = (v, ...rest) => (v !== undefined || rest.length === 0 ? v : firstOf(...rest));

export const getReportAddresses = report => {
    if (!report) return {};
    const addressDetails = report?.details?.address;

    let address2, address1;
    // if (addressDetails) {
    //     address2 = addressDetails.streetAddress;
    //     address1 = `${addressDetails.city}, ${addressDetails.state || addressDetails.community} ${addressDetails.zipcode
    //         }`;
    // } else {
    const splitAddress = report.address.split(',');
    address2 = splitAddress[0];
    address1 = splitAddress.slice(1).join(',');
    // }

    return {
        fullAddress: report?.address || '',
        address2: address2 || '',
        address1: address1 || '',
    };
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
};


//for making active link on cards when it reach the center of the viewport on mobiles (<=576px)

export const checkPosition = (containerRef, activeCardIndex, setActiveCardIndex) => {
    const elementsToObserve = containerRef?.current?.querySelectorAll(".observed-element");
    const windowHeight = window.innerHeight;

    const isScrolledToBottom = () => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        //check that the scroll has reached the end
        return scrollTop + windowHeight >= documentHeight - 1;
    };

    elementsToObserve?.forEach((element, index) => {

        const { top, height, bottom } = element?.getBoundingClientRect();
        const elementMidpoint = top + height / 2;
        const screenMidpoint = windowHeight / 2;

        // the middle of the element is within 30 pixels of the middle of the screen
        const isNearScreenMidpoint = elementMidpoint >= screenMidpoint - 30 && elementMidpoint <= screenMidpoint + 30;

        // Check for last element
        const isLastElementVisibleAndNearBottom =
            index === elementsToObserve.length - 1 &&
            top >= 0 && // top of the element is visible
            bottom <= windowHeight && // bottom of the element is visible
            isScrolledToBottom();

        (isNearScreenMidpoint || isLastElementVisibleAndNearBottom) && activeCardIndex !== index && setActiveCardIndex(index)

    });
}

//format string (using for data from server in by room)
export const formatString = (str) => {
    return str
        .replace(/_/g, ' ') // Заменяем все подчеркивания на пробелы
        .split(' ')          // Разбиваем строку на массив слов
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Делаем каждое слово с большой буквы
        .join(' ');          // Объединяем слова обратно в строку
}

// google tags
export const initializeGoogleTag = (measurementId) => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', measurementId);
};

export const trackEvent = (action, category, label, value = "") => {
    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
    });
};
