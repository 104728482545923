import {
    Bug,
    ChatsCircle,
    Couch,
    Fan,
    HouseLine,
    Pipe,
    Plug,
    Radioactive,
    SealWarning,
    Tree,
    Virus,
    Wall,
    Wrench
} from '@phosphor-icons/react';
import { track } from '@vercel/analytics/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import styles from './Issues.module.scss';

import { ReportAPI } from '@/api/report';
import pdfFile from '@/assets/icons/pdfFile.svg';
import roof from '@/assets/icons/roof.svg';
import upload_docs from "@/assets/icons/upload_docs.png";
import electrical_category from '@/assets/issues/electrical_category.png';
import hvac_category from '@/assets/issues/HVAC_category.png';
import roof_category from '@/assets/issues/roof_category.png';
import structural_category from '@/assets/issues/structural_category.png';
import termites_category from '@/assets/issues/termites_category.png';
import water_category from '@/assets/issues/water_damage.png';
import home from '@/assets/survey/oasisHouse1.svg';
import { Col, Flex, Row, Button, Icon } from '@/components/Layout';
import MobileTopBar from "@/components/mobileTopBar/mobileTopBar";
import { useDesktop, useDesktopMini, useLaptop, useLaptopMini, useMobile, useMobileMini } from "@/hooks/useAdaptives";
import { selectUserData } from '@/store/user/selectors';
import BigDisplayBlock from "@/views/PropertyInsights/issueComponents/bigDisplayBlock/bigDisplayBlock";
import { IssuesContainer } from "@/views/PropertyInsights/issueComponents/issuesContainer/issuesContainer";
import { IssueMenu } from "@/views/PropertyInsights/issueComponents/menu/issueMenu";
import { RecommendationsBlock } from "@/views/PropertyInsights/issueComponents/recommendationsBlock/recommendationsBlock";




// export const issue_category = {
//     "Roofing": <HouseLine size={24} style={{marginRight: '10px'}}/>,
//     "Electrical": <Plug size={24} style={{marginRight: '10px'}}/>,
//     "Plumbing": <Pipe size={24} style={{marginRight: '10px'}}/>,
//     "HVAC": <Fan size={24} style={{marginRight: '10px'}}/>,
//     "Mold": <Virus size={24} style={{marginRight: '10px'}}/>,
//     "Pest": <Bug size={24} style={{marginRight: '10px'}}/>,
//     "Water Damage": <SealWarning size={24} style={{marginRight: '10px'}}/>,
//     "Interior": <Couch size={24} style={{marginRight: '10px'}}/>,
//     "Radon": <Radioactive size={24} style={{marginRight: '10px'}}/>,
//     "Exterior": <Tree size={24} style={{marginRight: '10px'}}/>,
//     "Structural": <Wall size={24} style={{marginRight: '10px'}}/>,
//     "Foundation": <Wall size={24} style={{marginRight: '10px'}}/>,
// }

export const issue_category = {
    "Roofing": { icon: <HouseLine size={24} style={{ marginRight: '10px' }} />, image: roof_category },
    "Electrical": { icon: <Plug size={24} style={{ marginRight: '10px' }} />, image: electrical_category },
    "Plumbing": { icon: <Pipe size={24} style={{ marginRight: '10px' }} />, image: water_category },
    "HVAC": { icon: <Fan size={24} style={{ marginRight: '10px' }} />, image: hvac_category },
    "Mold": { icon: <Virus size={24} style={{ marginRight: '10px' }} />, image: home },
    "Pest": { icon: <Bug size={24} style={{ marginRight: '10px' }} />, image: termites_category },
    "Water damage": { icon: <SealWarning size={24} style={{ marginRight: '10px' }} />, image: water_category },
    "Interior": { icon: <Couch size={24} style={{ marginRight: '10px' }} />, image: home },
    "Radon": { icon: <Radioactive size={24} style={{ marginRight: '10px' }} />, image: home },
    "Exterior": { icon: <Tree size={24} style={{ marginRight: '10px' }} />, image: home },
    "Structural": { icon: <Wall size={24} style={{ marginRight: '10px' }} />, image: structural_category },
    "Foundation": { icon: <Wall size={24} style={{ marginRight: '10px' }} />, image: home },
}

export const Body = ({ propertyId, report, state = {} }) => {
    const { chosenReport } = useSelector(state => state.reports);
    const [issueType, setIssueType] = useState("urgent");
    const [urgentIssues, setUrgentIssues] = useState([]);
    const [moderateIssues, setModerateIssues] = useState([]);
    const [lowIssues, setLowIssues] = useState([]);
    const [displayIssue, setDisplayIssue] = useState([]);
    const [bigDisplay, setBigDisplay] = useState(null);
    const [currentIssue, setCurrentIssue] = useState("")
    const [showBig, setShowBig] = useState(false);
    const [priceColor, setPriceColor] = useState(styles.urgent);

    const [chatTaskId, setChatTaskId] = useState("")
    const [isWaitingForBotResponse, setIsWaitingForBotResponse] = useState(false);
    const [message, setMessage] = useState('');
    const initialBotMessage = "Hey there! I am the HomeScore Assistant! I'm here to help answer your questions "
    const [chatMessages, setChatMessages] = useState([]);
    const [userEmails, setUserEmails] = useState(Object());
    const [chat, setChat] = useState(false);
    const [chatId, setChatId] = useState(0)
    const [cardPlaces, setCardPlaces] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [loading, setLoading] = useState('.');
    const chatContainerRef = useRef(null);
    const user = useSelector(selectUserData);
    const [reportUrl, setReportUrl] = useState("")
    const [genChat, setGenChat] = useState(false)
    const isLaptop = useLaptop();
    const isLaptopMini = useLaptopMini();
    const isMobile = useMobile();
    const isMobileMini = useMobileMini();
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();
    const navigate = useNavigate();

    const getIssuesData = () => {
        const urgent = [];
        const moderate = [];
        const low = [];

        ReportAPI.getReportIssues(propertyId)
            .then(data => {
                if (report.has_report && data && data.length > 0) {
                    data.forEach((issue, i) => {
                        issue.id = i + 1;
                        if (issue.urgency === "High") {
                            urgent.push(issue);
                        } else if (issue.urgency === "Medium") {
                            moderate.push(issue);
                        } else if (issue.urgency === "Low") {
                            low.push(issue);
                        }
                    });

                    setUrgentIssues(urgent);
                    setModerateIssues(moderate);
                    setLowIssues(low);
                    setDisplayIssue(urgent);

                    ReportAPI.getReportUrl(propertyId).then(data => {
                        setReportUrl(data);
                    });
                } else if (report.has_report) {
                    setTimeout(getIssuesData, 3000);
                }
            })
            .catch(error => {
                console.error("Error fetching issues data:", error);

                // Retry even if an error occurs
                setTimeout(getIssuesData, 5000);
            });
    };


    const tabClick = (issueType) => {
        setIssueType(issueType)
        if (issueType === "urgent") {
            urgentIssues?.filter(x => x.status = false)
            setDisplayIssue(urgentIssues);
            setPriceColor(styles.urgent);
        } else if (issueType === "moderate") {
            moderateIssues?.filter(x => x.status = false)
            setDisplayIssue(moderateIssues);
            setPriceColor(styles.moderate);
        } else if (issueType === "low") {
            lowIssues?.filter(x => x.status = false)
            setDisplayIssue(lowIssues);
            setPriceColor(styles.low);
        }
        closeCard();
    }

    async function handleKeyDown(event) {
        if (event.key === 'Enter') {
            const newUserMessage = { content: message, message_type: "HUMAN", user: user.id };
            setChatMessages([...chatMessages, newUserMessage]);
            await ReportAPI.sendMessage(message, chatId, currentIssue).then(data => {
                setChatTaskId(data.task_id)
                setMessage("")
                setIsWaitingForBotResponse(true);
            })
            track("Sent Chat", { location: 'issues' });
        }
    }

    const handleMessage = (e, event) => {
        setMessage(event.target.value);
    }

    const handleMessageSend = async () => {
        if (message !== "" || message !== null || message !== undefined) {
            const newUserMessage = { content: message, message_type: "HUMAN", issue: currentIssue, user: user.id };
            setChatMessages([...chatMessages, newUserMessage]);
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            await ReportAPI.sendMessage(message, chatId, currentIssue).then(data => {
                setChatTaskId(data.task_id)
                setMessage("")
                setIsWaitingForBotResponse(true);
            })

            track("Sent Chat", { location: 'issues', issue_type: currentIssue });
        }
    }

    const handleChat = async (report_id, issue_name) => {
        setCurrentIssue(issue_name)
        await ReportAPI.fetchOrCreateChat(report_id, issue_name).then(data => {
            if (data?.messages?.length > 0) {
                setChatId(data.id)
                setChatMessages(data.messages)
            } else {
                setChatId(data.id)
                setChatMessages([{
                    content: initialBotMessage + `about ${issue_name}. How can I help?`,
                    message_type: "AI",
                    issue: issue_name
                }]);
            }
        })
    }

    const handleChatCardPlaces = async (issue, address) => {
        setIsLoading(true)
        const cardPlacesData = await ReportAPI.getChatCardPlaces(issue, address)
        const cardPlacesWithDetails = [];
        for (const place of cardPlacesData.results.slice(0, 3)) {
            const placeDetails = await ReportAPI.getCardPlaceDetails(place.place_id);
            const { website, international_phone_number } = placeDetails.results.result;
            cardPlacesWithDetails.push({
                ...place, details: {
                    website,
                    international_phone_number,
                },
            })
        }
        setIsLoading(false);
        setCardPlaces(cardPlacesWithDetails)
    }

    const handleCard = async (data) => {
        setCardPlaces([])
        const updatedIssues = displayIssue.map(i => ({ ...i, status: i.id === data.id }))
        handleChatCardPlaces(data.issue, report.address)
        setDisplayIssue(updatedIssues);
        setBigDisplay(data);
        setShowBig(true);
        handleChat(report.id, data.issue)
        chat && setChat(false)
    }

    const handleGenChat = () => {
        setGenChat(true);
        handleChat(report.id, "Home Repairs");
        chat && setChat(false)
    }

    const closeCard = () => {
        const issues = [...displayIssue]
        issues.map(issue => {
            issue.status = false;
        })
        setDisplayIssue(issues)
        setShowBig(false);
        setGenChat(false);
    }

    const goBack = () => {
        if (genChat || showBig) {
            genChat && setGenChat(false);
            showBig && setShowBig(false);
        } else {
            navigate('/property-organization')
        }
    }

    useEffect(() => {
        getIssuesData()
    }, [propertyId]);

    useEffect(() => {
        if (issueType === "urgent") {
            setDisplayIssue(urgentIssues);
            setPriceColor(styles.urgent);
        } else if (issueType === "moderate") {
            setDisplayIssue(moderateIssues);
            setPriceColor(styles.moderate);
        } else if (issueType === "low") {
            setDisplayIssue(lowIssues);
            setPriceColor(styles.low);
        }
        setBigDisplay(null);
        setShowBig(false);
    }, [issueType])


    useEffect(() => {
        let interval;
        if (isWaitingForBotResponse) {
            interval = setInterval(() => {
                setLoading((prevLoad) => {
                    // If there are already 3 dots, reset to one dot.
                    return prevLoad === '...' ? '.' : prevLoad + '.';
                });
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                ReportAPI.checkBotResponse(chatTaskId, chatId).then(data => {
                    if (data.state === "SUCCESS" || data.state === "FAIL") {
                        const newBotMessage = { content: data.result.content, message_type: "AI", issue: currentIssue };
                        setChatMessages([...chatMessages, newBotMessage]);
                        setIsWaitingForBotResponse(false);
                        clearInterval(interval);
                    }
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isWaitingForBotResponse, chatTaskId, chatMessages]);


    useEffect(() => {
        const chatContainer = document.getElementById("chatmessage");
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    }, [chatMessages, chat]);



    return (
        <>
            {!isDesktop && !isDesktopMini ?
                <MobileTopBar title={chosenReport?.name || ''} goBack={goBack} /> : null}
            {
                state.showPdf ? <div className={styles.pdfContainer}>
                    {report !== null && report.has_report ?
                        <>
                            <div className={styles.pdfContainer_header}>
                                <p className={'d-flex align-items-center gap-2'}>
                                    <Icon src={roof} className={'rounded-circle p-1 bg-white'} />
                                    Home Inspection
                                </p>
                            </div>
                            <iframe src={reportUrl}></iframe>
                        </>
                        : <h1>No report available. Please upload a report</h1>
                    }
                </div>
                    :
                    <Row className={`justify-content-start align-items-start gap-2 flex-nowrap flex-column flex-xl-row mb-3`}>
                        <IssueMenu issueType={issueType}
                            tabClick={tabClick}
                            showBig={showBig}
                            urgentIssues={urgentIssues}
                            moderateIssues={moderateIssues}
                            lowIssues={lowIssues} />

                        <div className={'w-100'}>
                            {/* General Chat */}
                            {genChat ?
                                <BigDisplayBlock>
                                    <BigDisplayBlock.Header icon={home}
                                        className={`w-10 ${styles.box} ${chat ? styles.smallBox1 : styles.box1} ${styles.general}`}
                                        chat={chat}>
                                        <h2 style={{ textAlign: 'left' }}>{"Home Repairs"}</h2>
                                    </BigDisplayBlock.Header>
                                    <BigDisplayBlock.Body chat={chat}
                                        className={`${styles.box} ${chat ? styles.smallBox2 : styles.box2}`}
                                        priceBtnTitle={`Overview`}
                                        priceBtnStyles={`${styles.price} ${styles.general} ${styles.priceBtn} ${styles.add_color}`}
                                        setChat={setChat}
                                        message={message}
                                        handleMessage={handleMessage}
                                        handleKeyDown={handleKeyDown}
                                        closeCard={closeCard}
                                        isMobileMini={isMobileMini}
                                        chatContainerRef={chatContainerRef}
                                        chatMessages={chatMessages}
                                        isWaitingForBotResponse={isWaitingForBotResponse}
                                        loading={loading}
                                        handleMessageSend={handleMessageSend}>
                                        <h2>Description</h2>
                                        <p className={'text-start mt-3'}>When you are
                                            considering a home, consider
                                            possible issues that can pose an immediate
                                            threat to the intergrity of the home,
                                            maintenance that affect its functionality,
                                            or minor cosmetic issues.</p>
                                    </BigDisplayBlock.Body>
                                </BigDisplayBlock>
                                :
                                // Issues Chat. cards
                                <Row className={`w-100 align-items-start ${showBig ? styles.showBigCards : ''}`}>
                                    {// (report?.summary_status !== 'Done' && urgentIssues.length == 0 && moderateIssues.length == 0 && lowIssues.length == 0) ?
                                        (report?.summary_status !== 'Done' && !urgentIssues.length && !moderateIssues.length && !lowIssues.length) ?
                                            <div><h2>Processing...</h2>
                                                <div className={styles.customLoader}></div>
                                            </div> : null
                                    }

                                    {
                                        showBig ?
                                            <BigDisplayBlock>
                                                <BigDisplayBlock.Header icon={issue_category[bigDisplay.category]?.image}
                                                    className={`${styles.box} ${chat ? styles.smallBox1 : styles.box1} ${priceColor}`}
                                                    chat={chat}>
                                                    <>
                                                        {issue_category[bigDisplay.category]?.icon}
                                                        <h2 style={{ textAlign: 'left' }}>{bigDisplay.issue}</h2>
                                                    </>
                                                </BigDisplayBlock.Header>
                                                <BigDisplayBlock.Body chat={chat}
                                                    className={`${styles.box} ${chat ? styles.smallBox2 : styles.box2}`}
                                                    priceBtnTitle={`${bigDisplay.cost_estimate_low} - ${bigDisplay.cost_estimate_high}`}
                                                    priceBtnStyles={`${styles.price} ${priceColor} ${styles.priceBtn}`}
                                                    setChat={setChat}
                                                    message={message}
                                                    handleMessage={handleMessage}
                                                    handleKeyDown={handleKeyDown}
                                                    closeCard={closeCard}
                                                    isMobileMini={isMobileMini}
                                                    chatContainerRef={chatContainerRef}
                                                    chatMessages={chatMessages}
                                                    isWaitingForBotResponse={isWaitingForBotResponse}
                                                    loading={loading}
                                                    handleMessageSend={handleMessageSend}>
                                                    <RecommendationsBlock isLoading={isLoading}
                                                        cardPlaces={cardPlaces} />
                                                    <h2>Recommendations</h2>
                                                    <p className={'text-start mt-3'}>
                                                        {bigDisplay.recommendation}
                                                    </p>
                                                </BigDisplayBlock.Body>
                                            </BigDisplayBlock> : null
                                    }

                                    {/*block with issues cards*/}
                                    {
                                        report && ((isDesktop || isDesktopMini) || (!isDesktop && !isDesktopMini && !showBig)) ? <IssuesContainer report={report}
                                            displayIssue={displayIssue}
                                            handleCard={handleCard}
                                            priceColor={priceColor} /> : null
                                    }
                                </Row>
                            }
                        </div>
                    </Row>
            }
            <div
                className={`btn btn-primary align-items-center justify-content-center rounded-circle position-fixed ${styles.fixed_chat_button}`}
                style={{
                    display: showBig || genChat ? 'none' : 'flex',
                    bottom: isLaptop || isLaptopMini || isMobile || isMobileMini ? '9rem' : '2.5rem'
                }}
                onClick={handleGenChat}>
                <ChatsCircle size={32} />
            </div>
        </>
    );
};

export const Controls = ({ setState, state, isDesktop, isDesktopMini, uploadOpen, setUploadOpen, report }) => (
    <>
        <Button style={{ padding: 10 }} onClick={() => setState({ state, showPdf: !state.showPdf })}>
            <Icon src={pdfFile} style={{ fontSize: 22 }} />
        </Button>
        {(isDesktop || isDesktopMini) && !report?.has_report ?
            <Col className={'ms-0 ms-xl-auto'}>
                <Button onClick={e => (e.preventDefault(), setUploadOpen(true))}
                    style={{ padding: '10px' }}>
                    <Icon src={upload_docs} size={22} />
                    {/*Upload Documents*/}
                </Button>
            </Col> : null
        }
    </>
);

Body.propTypes = {
    propertyId: PropTypes.string,
    report: PropTypes.object,
    state: PropTypes.object,
};

Controls.propTypes = {
    setState: PropTypes.func,
    state: PropTypes.object,
    isDesktop: PropTypes.bool,
    isDesktopMini: PropTypes.bool,
    uploadOpen: PropTypes.bool,
    setUploadOpen: PropTypes.func,
    report: PropTypes.object,
};
