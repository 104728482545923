import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';

import { ConditionRoute } from '@/components/ProtectedRoute';
import {setNavigate} from "@/navigateService";
import {LocationHistoryProvider} from "@/previousLocationContext";
import { selectIsLoggedIn, selectIsInitialized } from '@/store/user/selectors';
import { initializeUser } from '@/store/user/slice';
import {ChangePassword} from "@/views/ChangePassword/changePassword";
import { Comparison } from '@/views/Comparison';
import {ConfirmChangePassword} from "@/views/ConfirmChangePassword/confirmChangePssword";
import Dashboard from "@/views/Dashboard/dashboard";
import { EmailPassword } from '@/views/EmailPassword';
import { ForgotPassword } from '@/views/ForgotPassword';
import { Home } from '@/views/Home';
import KanbanScreen from "@/views/KanbanScreen/KanbanScreen";
import { Login } from '@/views/Login';
import { Logout } from '@/views/Logout';
import { MyProperties } from '@/views/MyProperties';
import { Password } from '@/views/Password';
import { Profile } from '@/views/Profile';
import { PropertyInsights } from '@/views/PropertyInsights';
import CertainRoomPage from "@/views/PropertyInsights/rooms/mobileScreenRooms/certainRoomPage/CertainRoomPage";
import MobileScreenRooms from '@/views/PropertyInsights/rooms/mobileScreenRooms/MobileScreenRooms';
import PropertyOrganizationPage from "@/views/PropertyOrganization/propertyOragnization";
import { QuizSummary } from '@/views/QuizSummary';
import { ResetPassword } from '@/views/ResetPassword/resetPassword';
import { Signup } from '@/views/Signup';
import Subscriptions from "@/views/Subscriptions/subscriptions";


export const Router = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isInitialized = useSelector(selectIsInitialized);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(initializeUser());
    }, [dispatch]);

    const navigate = useNavigate();

    useEffect(() => {
         setNavigate(navigate);
    }, [navigate]);

    return isInitialized ? (
        <LocationHistoryProvider>
        <Routes>
            <Route element={<ConditionRoute isAllowed={!isLoggedIn} />}>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Signup />} />
                <Route path="reset">
                    <Route path="" element={<ForgotPassword />} />
                    <Route path="confirmed" element={<EmailPassword />} />
                    <Route path={'password/:uid/:token'} element={<ResetPassword />}/>
                    <Route path={'key/done'} element={<ConfirmChangePassword />}/>
                </Route>
                <Route path="quiz" element={<QuizSummary inApp={false} />} />
            </Route>

                <Route element={<ConditionRoute isAllowed={isLoggedIn} redirectPath="/register"/>}>
                    <Route path="" element={<Navigate to={'my-properties'} replace={true}/>}/>
                    <Route path="my-properties">
                        <Route path="" element={<KanbanScreen/>}/>
                        <Route path="insights/:propertyId" element={<PropertyInsights/>}/>
                    </Route>
                    <Route path="dashboard" element={<Home />} />
                    <Route path="property-organization" element={<PropertyOrganizationPage/>}/>
                    <Route path="rooms" element={<MobileScreenRooms/>}/>
                    <Route path="rooms/:room_type" element={<CertainRoomPage/>}/>
                    <Route path="subscriptions" element={<Subscriptions/>}/>

                    {/*<Route path={"property-organization"}>*/}
                    {/*    */}
                    {/*</Route>*/}

                    <Route path="summary" element={<QuizSummary/>}/>
                    <Route path="comparison" element={<Comparison/>}/>
                    <Route path="profile" element={<Profile/>}/>
                    <Route path="password" element={<Password/>}/>
                    <Route path="logout" element={<Logout/>}/>
                </Route>
            </Routes>
        </LocationHistoryProvider>
    ) : (
        ''
    );
};
