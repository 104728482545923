import React from "react";

import styles from './subscriptions.module.scss';

import { Contents, Layout as MainLayout, Layout, MobileLayout } from "@/components/Layout";
import MobileTopBar from "@/components/mobileTopBar/mobileTopBar";
import { useDesktop, useDesktopMini, useMobile, useMobileMini } from "@/hooks/useAdaptives";
import { SelectPlan } from "@/views/SelectPlan/selectPlan";

const Subscriptions = () => {
    const isMobile = useMobile();
    const isMobileMini = useMobileMini();
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();

    const Layout = !isDesktop && !isDesktopMini ? MobileLayout : MainLayout;

    return (
        <Layout mainAddClass={!isDesktop && !isDesktopMini ? styles.mobile_main : ''}>
            {!isDesktop && !isDesktopMini ? <MobileTopBar title={''} style={{ background: 'inherit' }} /> : null}
            <Contents className={'d-flex flex-column flex-grow-1 pb-4 mx-auto'}>
                <SelectPlan />
            </Contents>
        </Layout>
    )
}

Subscriptions.propTypes = {

}

export default Subscriptions;