import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { StripeAPI } from "@/api/stripe";

const initialState = {
    remaining: null,
};

// Thunk for fetching subscription info and updating state with remaining limit
export const getSubInfo = createAsyncThunk(
    'stripe/getRemaining',
    async (_, { dispatch }) => {  // _ for an unused parameter
        const info = await StripeAPI.checkPayment(); // Await the API call
        const limit = info?.limits?.remaining;
        dispatch(getRemaining(limit));
    }
);

export const stripeSlice = createSlice({
    name: 'stripe',
    initialState,
    reducers: {
        getRemaining: (state, action) => {
            state.remaining = action.payload;
        },
    },
});

export const { getRemaining } = stripeSlice.actions;

export const stripeReducer = stripeSlice.reducer;