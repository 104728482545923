import { XCircle } from "@phosphor-icons/react";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";


import styles from './kanbanCard.module.scss';

import { ReportAPI } from "@/api/report";
import fillerHouse from "@/assets/fillerHouse.png";
import arrow from '@/assets/kanbanCard/ArrowDownLeft.png';
import bookMark from '@/assets/kanbanCard/BookmarkSimple.png';
import close from '@/assets/kanbanCard/close.png';
import vector from '@/assets/kanbanCard/Vector.png';
import { Icon, Img } from "@/components/Layout";
import { useDesktop, useDesktopMini } from "@/hooks/useAdaptives";
import { dispatch } from "@/store";
import { chooseReport } from "@/store/reports/reportsSlice";
import { getReportAddresses } from "@/utils";



export const ItemType = 'KANBAN CARD';
const KanbanCard = ({
    index, report, className = '',
    comparing = () => {
    }, preventClick, removeReport = (id) => {
    }, ...props
}) => {
    if (!report) return null;

    const [refreshedReport, setRefreshedReport] = useState(report);
    const image = refreshedReport.details.imgSrc;
    const insightsLink = `/my-properties/insights/${refreshedReport?.id}`;
    const { address1, address2 } = getReportAddresses(refreshedReport);
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();
    const navigate = useNavigate();

    const deleteCard = (e) => {
        e.stopPropagation();
        e.preventDefault();
        removeReport(report.id)
    }

    // const checkLink = refreshedReport?.details.mlsid && refreshedReport?.details_status !== "Extracting details..." && refreshedReport?.details_status !== ""
    //     ? insightsLink : '#';
    const checkLink = (refreshedReport.details_status === 'Done' || refreshedReport.details_status === 'Error')
        ? insightsLink : '#';

    const link = checkLink === '#' ? '#' : (!isDesktop && !isDesktopMini ? `/property-organization` : checkLink);

    const onCardClick = (e) => {
        preventClick && preventClick(e);
        link !== '#' && !isDesktopMini && !isDesktop && dispatch(chooseReport(refreshedReport))
    }

    useEffect(() => {
        let interval = (refreshedReport.details_status !== 'Done' || refreshedReport.details_status !== 'Error') ? setInterval(() => {
            ReportAPI.getReport(refreshedReport.id).then(data => {
                setRefreshedReport(data);
                (data.details_status === 'Done' || data.details_status === 'Error') && clearInterval(interval);
            })
        }, 3000) : null

        return () => {
            interval && clearInterval(interval)
        }
    }, [])

    return (
        <NavLink
            to={link}
            className={styles.card} {...props}
            onClick={onCardClick}
            id={report.id}>
            <Img src={image || fillerHouse} preloadSrc={fillerHouse} className={styles.main_image} />

            <div
                className={`${styles.close_btn} ${styles.common_btn} rounded-circle d-flex align-items-center justify-content-center`}
                onClick={deleteCard}>
                <Icon src={close} />
            </div>

            <div className={`${styles.info_block} d-flex align-items-center justify-content-between rounded-pill`}>
                <div className={styles.addresses}>
                    <p className={styles.address2}>{refreshedReport?.name === "Sample Report" ? "Sample Report" : address2}</p>
                    <div className={'d-flex gap-2 align-items-center'}>
                        {(refreshedReport.details_status !== 'Done' && refreshedReport.details_status !== 'Error')
                            ? <div className="spinner-border text-white" style={{ width: '10px', height: '10px' }} role="status"></div>
                            : refreshedReport?.name !== "Sample Report" ? <Icon src={vector}
                                className={'flex-shrink-0'}
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    minWidth: '10px'
                                }} /> : null}
                        {(refreshedReport.details_status !== 'Done' && refreshedReport.details_status !== 'Error') ? <p className={styles.address1}>Loading Report</p>
                            :
                            <p className={styles.address1}>{refreshedReport?.name === "Sample Report" ? "" : address1}</p>}
                    </div>
                </div>

                <div
                    className={`${styles.common_btn} ${styles.link_btn} rounded-circle d-flex align-items-center justify-content-center`}>
                    <Icon src={arrow} />
                </div>
            </div>
        </NavLink>
    )
}

KanbanCard.propTypes = {
    index: PropTypes.number,
    report: PropTypes.object,
    className: PropTypes.string,
    comparing: PropTypes.func,
    preventClick: PropTypes.func,
    removeReport: PropTypes.func
};

export default KanbanCard;