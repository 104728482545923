import { track } from '@vercel/analytics/react';

import { api } from './config';

export const SubscriptionPlans = {
    LIMITED_SUBSCRIPTION: '+5 Properties',
    HIGHEST_SUBSCRIPTION: 'Launch Offer',
}

export const StripeAPI = {
    //we need to get key for stripe
    async setup() {
        const response = await api.get(`/subscriptions/pricing_table_json/`);
        return response.data
    },

    async getSubscriptionInfo() {
        const response = await api.get(`/subscriptions/`);
        return response.data
    },

    //we need to get info about user subscriptions
    async checkPayment() {
        const response = await api.get(`/ecommerce/purchases/json/`);
        return response.data
    },

    async createSession() {
        const response = await api.post("/subscriptions/api/create-checkout-session",);
        return response.data
    },

    async portal() {
        const response = await api.post("/subscriptions/stripe/api/create-portal-session");
        return response.data
    },
}