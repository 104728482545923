import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import styles from './selectPlan.module.scss';

import { StripeAPI } from "@/api/stripe";
import { selectUserData } from '@/store/user/selectors';

const STRIPE_PRICING_TABLE_ID = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const SelectPlan = ({ }) => {

    const user = useSelector(selectUserData);


    return (
        <div className={styles.main_wrapper}>
            <stripe-pricing-table
                pricing-table-id={STRIPE_PRICING_TABLE_ID}
                publishable-key={STRIPE_PUBLISHABLE_KEY}
                customer-email={user.email}
            >
            </stripe-pricing-table>
        </div>
    )
}