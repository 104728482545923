import { track } from '@vercel/analytics/react';
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";

import styles from './resetPassword.module.scss';

import { UserAPI } from "@/api/user";
import { Authentication } from '@/components/Authentication';
import { Button, Input, Row } from "@/components/Layout";
import { selectLoading } from "@/store/user/selectors";

export const ResetPassword = () => {
    const { uid, token } = useParams();  // Get uid and token from the URL
    const dispatch = useDispatch();
    const loading = useSelector(selectLoading);
    const navigate = useNavigate();

    const [password, setPassword] = useState({
        new_password: "",
        repeat: "",
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password.new_password !== password.repeat) {
            toast.warn("New password does not match");
        } else {
            try {
                const response = await UserAPI.resetPassword(uid, token, password.new_password, password.repeat);
                // console.log(response);
                setPassword({ ...password, curr_password: '', new_password: '', repeat: '' });
                track("Password Reset", { status: "Success", message: "Success" });
                navigate('/reset/key/done');
            } catch (error) {
                console.error(error);
                toast.error(error.response?.data?.message || "An error occurred while resetting the password");
                track("Password Reset", { status: "Failure", message: error.response?.data?.message || "Error" });
            }
        }
    }

    const handleChange = (e, event) => {
        setPassword({ ...password, [event.target.name]: event.target.value });
    }



    return (
        <Authentication subtitle='Reset Password' seeEmail={false} seePassword={false}
            buttonType='Reset Password'
            // onClick={login}
            loading={loading}>
            <form className={styles.data_card} onSubmit={handleSubmit}>
                <div className={styles.profile_data}>
                    <label>New Password</label>
                    <Input
                        className={`${styles.input} w-100 border rounded-pill`}
                        type="password"
                        value={password.new_password}
                        placeholder="New Password"
                        onChange={handleChange}
                        name="new_password"
                        required
                    />
                    <ul>
                        <li>Your password can&apos;t be too similar to your other personal information.</li>
                        <li>Your password must contain at least 8 characters.</li>
                        <li>Your password can&apos;t be a commonly used password.</li>
                        <li>Your password can&apos;t be entirely numeric.</li>
                    </ul>
                </div>
                <div className={styles.profile_data}>
                    <label>New Password &#40;Again&#41;</label>
                    <Input
                        className={`${styles.input} w-100 border rounded-pill`}
                        type="password"
                        value={password.repeat}
                        placeholder="New Password"
                        onChange={handleChange}
                        name="repeat"
                        required
                    />
                </div>
                <Button type="submit" className={'mx-auto my-0 w-100 py-3 mt-3'} style={{ fontSize: '1rem' }}>Reset Password</Button>
            </form>
            <Row className={`${styles.links_wrapper} flex-nowrap`}>
                <Link to={'/login'} className={'text-center text-decoration-underline'}>
                    Have an account?
                </Link>
                <Link to={'/register'} className={'text-center text-decoration-underline'}>
                    Don&apos;t have an account?
                </Link>
            </Row>
        </Authentication>
    );
}