import {
    Bed, Star, Sun,
    Trophy,
    Wrench
} from "@phosphor-icons/react";
import { track } from "@vercel/analytics/react";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from './MainScrrenRooms.module.scss';

import { ReportAPI } from "@/api/report";
import kitchen from "@/assets/rooms/kitchen.png";
import living_room from "@/assets/rooms/living_room.png";
import master_bedroom from "@/assets/rooms/master_bedroom.png";
import exterior from '@/assets/rooms/roof.png';
import bathroom from '@/assets/rooms/unique_design.png';
import Card, { CardLinkPosition, CardType } from "@/components/Card";
import { Row } from "@/components/Layout";
import { useDesktop, useDesktopMini, useMobileMini } from "@/hooks/useAdaptives";
import { dispatch } from "@/store";
import { rooms_features } from "@/store/constants";
import { chooseReport } from "@/store/reports/reportsSlice";
import { selectUserData } from "@/store/user/selectors";
import BigDisplayBlock from "@/views/PropertyInsights/issueComponents/bigDisplayBlock/bigDisplayBlock";
import commonStyles from "@/views/PropertyInsights/Issues.module.scss";
import RoomsMenu from "@/views/PropertyInsights/rooms/mainScreenRooms/roomsMenu/RoomsMenu";


const HighlightedText = ({ text, wordsToHighlight }) => {
    const highlightedText = text.split(' ').map((word, index) => {
        const cleanWord = word.replace(/[.,!?;:()]/g, '');
        if (wordsToHighlight.includes(cleanWord.toLowerCase())) {
            return <span key={index} className={word.toLowerCase() === 'pool' ? styles.highlight_pool
                : cleanWord.toLowerCase() === 'patio' ? styles.highlight_patio : ''}>{word} </span>;
        }
        return word + ' ';
    });

    return <p>{highlightedText}</p>;
};

HighlightedText.propTypes = {
    text: PropTypes.string,
    wordsToHighlight: PropTypes.arrayOf(PropTypes.string),
}
export const Body = ({ propertyId, report }) => {
    const [rooms, setRooms] = useState([]);
    const [loadingRoom, setLoadingRooms] = useState(true);
    const [activeMenuItem, setActiveMenuItem] = useState({});
    const [features, setFeatures] = useState({})

    const roomMenuImages = {
        'Exterior': exterior,
        'Living Room': living_room,
        'Kitchen': kitchen,
        'Bedroom': master_bedroom,
        'Bathroom': bathroom,
    }

    const exteriorValues = [
        "Roofing",
        "Siding",
        "Lot Size Openness",
        "Amenities",
        "Architectural Style",
        "Property Exterior Quality",
        "Accessibility"
    ]

    function formatRoomType(roomType) {
        if (roomType === null || roomType === undefined) {
            return ''
        }

        return roomType
            .split('_') // Split by underscores
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
            .join(' '); // Join them back with spaces
    }

    useEffect(() => {
        const getRooms = async () => {
            setLoadingRooms(true);
            const items = await ReportAPI.getRooms(propertyId);
            setLoadingRooms(false);
            let id = 1
            const menuItems = []
            const feats = {}
            for (const key in items.data.message.images) {
                if (items.data.message.images.hasOwnProperty(key)) {
                    menuItems.push({
                        id: id,
                        type: key,
                        title: formatRoomType(items.data.message.images[key].room_type),
                        description: '',
                        // image: roomMenuImages[id - 1]
                        image: roomMenuImages[key]
                    });

                    if (key === 'Exterior') {
                        const check = (item) => {
                            for (let i = 0; i < exteriorValues.length; i++) {
                                if (exteriorValues[i] === item) {
                                    return true;
                                }
                            }
                            return false;
                        }
                        const filtered = items.data.message.images[key].features.filter(item => check(item.feature))
                        feats[key] = filtered;
                    } else {
                        feats[key] = items.data.message.images[key].features
                    }
                }
                id += 1;
            }
            setRooms(menuItems);
            setActiveMenuItem(menuItems[0]);
            setFeatures(feats);
        }

        report?.room_summary_status === 'Done' && getRooms()
    }, [report?.room_summary_status])


    const navigate = useNavigate();
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();

    const [hover, setHover] = useState({
        id: -1,
        isHovered: false
    });
    const [chatTaskId, setChatTaskId] = useState("");
    const [isWaitingForBotResponse, setIsWaitingForBotResponse] = useState(false);
    //for showing chat in bigDisplayBlock component
    const [chat, setChat] = useState(false);
    const [chatId, setChatId] = useState(0);
    const initialBotMessage = "Hey there! I am the HomeScore Assistant! I'm here to help answer your questions ";
    const [chosenFeature, setChosenFeature] = useState(null);
    const [chatMessages, setChatMessages] = useState([{
        content: initialBotMessage + `about ` + formatRoomType(chosenFeature?.feature) + `. How can I help?`,
        message_type: "AI",
        issue: chosenFeature?.feature
    }]);
    const [loading, setLoading] = useState('.');
    const [message, setMessage] = useState('');
    const user = useSelector(selectUserData);
    const chatContainerRef = useRef(null);
    const isMobileMini = useMobileMini();

    const [isShowBig, setIsShowBig] = useState(false);
    //if we had big display open we need to filtering cards (not show card in right side if its chosen for displaying)
    const [featuresToShow, setFeaturesToShow] = useState([]);

    //choose feature (clicking on certain feature card)
    const chooseFeature = useCallback(async (feature) => {
        !isShowBig && setIsShowBig(true);
        chat && setChat(false);
        setChosenFeature(feature);

        await ReportAPI.fetchOrCreateChat(report?.id, feature?.feature).then(data => {
            const emptyChatMessages = [{
                content: initialBotMessage + `about ${feature?.feature}. How can I help?`,
                message_type: "AI",
                issue: feature?.feature
            }]
            setChatId(data.id);
            data?.messages?.length > 0 ? setChatMessages(data.messages) : setChatMessages(emptyChatMessages)
        })

    }, [report, chat, isShowBig])

    const closeCard = () => {
        setIsShowBig(!isShowBig);
        setChosenFeature(null);
        setChat(false);

        setChatId(0);
        setChatMessages([{
            content: initialBotMessage + `about ` + formatRoomType(chosenFeature?.feature) + `. How can I help?`,
            message_type: "AI",
            issue: ''
        }])
    }

    const handleMessage = (e, event) => {
        setMessage(event.target.value);
    }

    const handleMessageSend = async () => {
        if (message !== "" || message !== null || message !== undefined) {
            const newUserMessage = { content: message, message_type: "HUMAN", issue: chosenFeature?.feature, user: user.id };
            setChatMessages([...chatMessages, newUserMessage]);
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            await ReportAPI.sendMessage(message, chatId, chosenFeature?.feature).then(data => {
                setChatTaskId(data.task_id)
                setMessage("")
                setIsWaitingForBotResponse(true);
            })
            track("Sent Chat", { location: 'rooms', issue_type: chosenFeature?.feature });
        }
    }

    async function handleKeyDown(event) {
        if (event.key === 'Enter') {
            const newUserMessage = { content: message, message_type: "HUMAN", issue: chosenFeature?.feature, user: user.id };
            setChatMessages([...chatMessages, newUserMessage]);

            await ReportAPI.sendMessage(message, chatId, chosenFeature?.feature).then(data => {
                setChatTaskId(data.task_id)
                setMessage("")
                setIsWaitingForBotResponse(true);
            })
            track("Sent Chat", { location: 'rooms', issue_type: chosenFeature?.feature });
        }
    }


    useEffect(() => {
        let interval;
        if (isWaitingForBotResponse) {
            interval = setInterval(() => {
                setLoading((prevLoad) => {
                    // If there are already 3 dots, reset to one dot.
                    return prevLoad === '...' ? '.' : prevLoad + '.';
                });
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                ReportAPI.checkBotResponse(chatTaskId, chatId).then(data => {
                    if (data.state === "SUCCESS" || data.state === "FAIL") {
                        const newBotMessage = { content: data.result.content, message_type: "AI", issue: chosenFeature?.feature };
                        setChatMessages([...chatMessages, newBotMessage]);
                        setIsWaitingForBotResponse(false);
                        clearInterval(interval);
                    }
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isWaitingForBotResponse, chatTaskId, chatMessages, chosenFeature]);


    const rankValues = {
        "Size Rank": <Trophy size={15} color='#ee1111' />,
        "Above Average": <Bed size={15} color='#06a5db' />,
        "Quality Rank": <Star size={15} color='#fbff05' />,
        "Average Condition": <Wrench size={15} color='#3400c2' />,
        "Sunlight Rank": <Sun size={15} color='#fbff05' />,
    }

    useEffect(() => {
        setIsShowBig(false);
        setChat(false);
    }, [activeMenuItem])

    useEffect(() => {
        if (!isDesktop && !isDesktopMini) {
            navigate('/')
        }
    }, [isDesktop, isDesktopMini])

    //not showing small card between all small cards id its chosen
    useEffect(() => {
        if (Object.keys(features).length !== 0) {
            const filteredFeatures = features[activeMenuItem?.type].filter(f => f?.feature !== chosenFeature?.feature);
            setFeaturesToShow(isShowBig ? filteredFeatures : features[activeMenuItem?.type])
        }
    }, [features, isShowBig, chosenFeature, activeMenuItem])

    useEffect(() => {
        let interval = report && report?.room_summary_status !== 'Done' && report?.name !== 'Sample Report' ? setInterval(() => {
            ReportAPI.getReport(report.id).then(data => {
                if (data.room_summary_status === 'Done') {
                    dispatch(chooseReport(data))
                    clearInterval(interval);
                }

            })
        }, 12000) : null

        return () => {
            interval && clearInterval(interval)
        }
    }, [])

    return (

        <div className={styles.main_screen}>
            {loadingRoom || report?.room_summary_status !== 'Done' ?
                (
                    <Row style={{ justifyContent: 'center' }}>
                        <h1>
                            {report?.room_summary_status !== 'Done' ? 'Processing...this may take ~1 min' : 'Loading Rooms...'}
                        </h1>
                        <div className="spinner-border text-dark" role="status">
                        </div>
                    </Row>
                )
                :
                (
                    <Row
                        className={`justify-content-start align-items-start gap-3 flex-nowrap flex-column flex-lg-row mb-3`}>

                        <div className={styles.menu_wrapper}>
                            <RoomsMenu menuItems={rooms} activeMenuItem={activeMenuItem} setActiveMenuItem={setActiveMenuItem} />
                        </div>
                        <div className={'w-100'}>
                            <Row className={`w-100  ${styles.showBigCards}`}>

                                {
                                    isShowBig ?
                                        <div className={styles.chat_block_wrapper}>
                                            <BigDisplayBlock className={'flex-grow-1'}>
                                                <BigDisplayBlock.Header /*icon={roomFeatures[chosenFeature.title].image}*/
                                                    className={`${commonStyles.box} ${chat ? commonStyles.smallBox1 : commonStyles.box1} ${commonStyles.general}`}
                                                    chat={chat}>
                                                    <>
                                                        {rooms_features[formatRoomType(chosenFeature?.feature)]?.icon}
                                                        <h2 style={{ textAlign: 'left' }}>{formatRoomType(chosenFeature.feature)}</h2>
                                                    </>
                                                </BigDisplayBlock.Header>
                                                <BigDisplayBlock.Body
                                                    chat={chat}
                                                    className={`${commonStyles.box} ${chat ? commonStyles.smallBox2 : commonStyles.box2}`}
                                                    setChat={setChat}
                                                    message={message}
                                                    handleMessage={handleMessage}
                                                    handleKeyDown={handleKeyDown}
                                                    //  closeCard={closeCard}
                                                    isMobileMini={isMobileMini}
                                                    chatContainerRef={chatContainerRef}
                                                    chatMessages={chatMessages}
                                                    isWaitingForBotResponse={isWaitingForBotResponse}
                                                    loading={loading}
                                                    handleMessageSend={handleMessageSend}
                                                    closeCard={closeCard}
                                                //  withoutBodyAnimation={true}
                                                >
                                                    {
                                                        <div
                                                            className={styles.features_block}
                                                            style={{ flexDirection: 'column', justifyContent: 'flex-start' }}
                                                        >
                                                            <h1 style={{ textAlign: 'start' }}>
                                                                Description
                                                            </h1>
                                                            <p>{chosenFeature.summary}</p>
                                                        </div>
                                                    }
                                                </BigDisplayBlock.Body>
                                            </BigDisplayBlock>
                                        </div> : null
                                }

                                <div className={isShowBig ? styles.cards_wrapper_with_big_block : styles.cards_wrapper_full_width}>
                                    {
                                        featuresToShow?.map((feature, index) => {
                                            return (
                                                <Card
                                                    key={index}
                                                    className={styles.card}
                                                    onClick={() => {
                                                        chooseFeature(feature);
                                                    }}
                                                    type={CardType.smallBox}
                                                    linkPosition={CardLinkPosition.topRight}
                                                    action={() => {
                                                        chooseFeature(feature);
                                                    }}
                                                    onMouseEnter={() => {
                                                        setHover({
                                                            id: index,
                                                            isHovered: true
                                                        })
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHover({
                                                            id: -1,
                                                            isHovered: false
                                                        })
                                                    }}
                                                >
                                                    <Card.Header
                                                        className={`${styles.card_header}  w-100 d-flex align-items-center gap-2 py-0 mb-2`}
                                                    >
                                                        <div className={styles.icon_wrapper}>{rooms_features[formatRoomType(feature?.feature)]?.icon}</div>
                                                        <p>
                                                            {hover.isHovered && feature?.feature?.length >= 16 && index === hover.id ?
                                                                formatRoomType(feature?.feature.slice(0, 14)) + '...'
                                                                :
                                                                formatRoomType(feature?.feature)}
                                                        </p>
                                                    </Card.Header>
                                                    <Card.Body className={styles.card_body}>
                                                        {
                                                            feature?.values.length > 0
                                                                ?
                                                                <div
                                                                    className={styles.value_wrapper}
                                                                    style={{
                                                                        color: 'white',
                                                                        backgroundColor: rooms_features[formatRoomType(feature?.feature)]?.bg || '#5F5F5F'
                                                                    }}>
                                                                    {
                                                                        (feature?.values.length > 1 && (feature?.values[0] === "none" || feature?.values[0] === "None")) ?
                                                                            formatRoomType(feature?.values[1])
                                                                            :
                                                                            formatRoomType(feature?.values[0])
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            feature?.summary ?
                                                                <div
                                                                    className={styles.description_wrapper}
                                                                    style={{ maxHeight: '65px', overflowY: 'auto' }}
                                                                >
                                                                    {
                                                                        feature?.summary
                                                                    }
                                                                </div> : null
                                                        }

                                                        {
                                                            <div className={styles.ranks_with_img_wrapper}>
                                                                <div className={styles.ranks_image_wrapper}
                                                                    style={{ display: 'block' }}>
                                                                    <img src={rooms_features[formatRoomType(feature?.feature)]?.image ? rooms_features[formatRoomType(feature?.feature)]?.image : master_bedroom} />
                                                                </div>
                                                            </div>
                                                        }
                                                    </Card.Body>
                                                </Card>
                                            )
                                        })
                                    }
                                </div>
                            </Row>
                        </div>
                    </Row >
                )
            }
        </div >

    )
}

Body.propTypes = {
    propertyId: PropTypes.string,
    report: PropTypes.object,
};

export const Controls = props => <></>;