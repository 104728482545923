import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

import styles from './blockWithGraph.module.scss';

import cap from "@/assets/icons/cap.svg";
import hourglass from "@/assets/icons/hourglass.svg";
import thinX from "@/assets/icons/thinXShape.svg";
import xShape from "@/assets/icons/xShape.svg";
import schoolBag from "@/assets/insights/backpack.svg";
import calendar from "@/assets/insights/calendar.svg";
import tape from "@/assets/insights/measuringTape.svg";
import Card, { CardLinkPosition, CardType } from "@/components/Card";
import { Col, Flex, Row, Img } from "@/components/Layout";
import { Survey } from "@/components/v1Survey";
import { useDesktop, useDesktopMini, useMobileAdditional, useMobileMini } from "@/hooks/useAdaptives";
import { checkPosition } from "@/utils";
import { chartOptions, setPathColor } from "@/views/PropertyInsights/Main";


export const BlockWithGraph = ({ userPreferences, fit, chartData, preferences, mergeData, handleCard, report, addressDetails }) => {

    const isMobileMini = useMobileMini();
    const isMobileAdd = useMobileAdditional();
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();

    //for appearing arrow link
    const [activeCardIndex, setActiveCardIndex] = useState(null);
    const containerRef = useRef(null);

    // function from utils
    const checkElementPositions = useCallback(() => checkPosition(containerRef, activeCardIndex, setActiveCardIndex), [activeCardIndex]);

    const quizBtnForDesktop = isDesktop || isDesktopMini ?
        <div className={'ms-auto'}><Survey title='Enhance personal preferences' /></div> : null;

    useEffect(() => {
        if (isMobileMini) {
            window.addEventListener("scroll", checkElementPositions);

            return () => {
                window.removeEventListener("scroll", checkElementPositions);
            };
        }
    }, [isMobileMini])

    return (
        <Row className={`${styles.no_big_display_wrapper} w-100 flex-nowrap flex-column flex-xl-row mx-auto gap-2`}>
            <Card type={CardType.bigBox} className={styles.chartCard}>
                <Card.Header icon={xShape}>
                    <span>Overview</span>
                    {(userPreferences === null || userPreferences.length < 8 ? quizBtnForDesktop :
                        // <Survey title='Enhance personal preferences'/> :
                        <span className={`${fit.title_style} ms-auto`}>{fit.label}</span>
                    )}
                </Card.Header>
                <Card.Body>
                    {chartData && preferences ?
                        (
                            <div className={`${styles.chartContainer}`}>
                                <Chart type="radar" options={chartOptions} data={mergeData}
                                    className={'w-100 h-100'} />
                            </div>
                        ) :
                        null
                    }
                </Card.Body>
            </Card>
            <div className={`d-flex flex-column align-items-start flex-shrink-0 ${styles.cards_wrapper}`} ref={containerRef}>
                <Row className={`mb-2 w-100 gap-2 justify-content-center ` + ((isDesktop || isDesktopMini) && (`flex-column flex-sm-row ${styles.row_for_small_cards}`))}>
                    <Card className={`${styles.small_card} text-start observed-element`}
                        data-id={1}
                        hoveredClass={isMobileMini && activeCardIndex === 0}
                        type={CardType.smallBox}
                        linkPosition={CardLinkPosition.topRight}
                        onClick={() => {
                            handleCard('size')
                        }}
                    >
                        <Card.Header icon={thinX}>Home Size</Card.Header>
                        <Card.Body>
                            <div className={styles.small_card_body}>
                                <h1 className={'d-inline'} style={{ fontSize: '4rem' }}>{report?.details?.livingArea ? report?.details?.livingArea : 'N/A'}</h1>
                                <p className={'d-inline ms-1'}>{report?.details?.livingAreaUnits}</p>
                                <Img src={tape} />
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className={`${styles.small_card} text-start observed-element`}
                        data-id={2}
                        hoveredClass={isMobileMini && activeCardIndex === 1}
                        type={CardType.smallBox}
                        linkPosition={CardLinkPosition.topRight}
                        onClick={() => {
                            handleCard('year')
                        }}
                    >
                        <Card.Header icon={hourglass}>Year Built</Card.Header>
                        <Card.Body>
                            <div className={styles.small_card_body}>
                                <h1 className={'d-inline'} style={{ fontSize: '4rem' }}>{report?.details?.yearBuilt ? report?.details?.yearBuilt : 'N/A'}</h1>
                                <p className={'d-inline ms-1'}></p>
                                <Img src={calendar} />
                            </div>
                        </Card.Body>
                    </Card>
                </Row>
                <Row className={`w-100 justify-content-center`}>
                    <Card className={'observed-element'}
                        data-id={3}
                        hoveredClass={isMobileMini && activeCardIndex === 2}
                        type={isMobileAdd ? CardType.smallBox : CardType.bigLong}
                        style={{ maxWidth: isMobileAdd ? '' : '760px' }}
                        linkPosition={CardLinkPosition.topRight}
                        onClick={() => {
                            handleCard('school')
                        }}
                    >
                        <Card.Header icon={cap}>{addressDetails?.city} Schools</Card.Header>
                        <Card.Body>
                            <Flex>
                                <Img src={schoolBag} style={isMobileAdd ? { display: 'none' } : { marginRight: '1.25rem', width: '25%' }} />
                                <div>
                                    {report?.details?.schools?.map?.(school => (
                                        <Row className={'flex-nowrap justify-content-start gap-3 my-2 mx-0'} key={school.link}>
                                            <div className={'flex-shrink-0'} style={{ maxWidth: '3.75rem' }}>
                                                <CircularProgressbar
                                                    value={school.rating}
                                                    maxValue={10}
                                                    text={`${school.rating}/10`}
                                                    styles={buildStyles(setPathColor(school.rating))}
                                                />
                                            </div>
                                            <div className={'text-start'} style={{ maxWidth: '15rem' }}>{school.name}</div>
                                        </Row>
                                    )) ||
                                        (
                                            <Row className={'flex-nowrap justify-content-start gap-3 my-2 mx-0'}>
                                                <div className={'flex-shrink-0'} style={{ maxWidth: '3.75rem' }}>
                                                    <CircularProgressbar
                                                        value={0}
                                                        maxValue={10}
                                                        text={`N/A`}
                                                        styles={buildStyles({ textColor: 'black', pathColor: 'black' })}
                                                    />
                                                </div>
                                                <div className={'text-start'}>No schools were located nearby</div>
                                            </Row>
                                        )
                                    }
                                </div>
                            </Flex>
                        </Card.Body>
                    </Card>
                </Row>
            </div>
        </Row>
    )
}

BlockWithGraph.propTypes = {
    userPreferences: PropTypes.array,
    fit: PropTypes.object,
    chartData: PropTypes.any,
    preferences: PropTypes.any,
    mergeData: PropTypes.any,
    handleCard: PropTypes.func,
    report: PropTypes.object,
    addressDetails: PropTypes.string,
};