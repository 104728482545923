import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";

import { ReactComponent as GuideIcon } from './../../assets/mobileMenu/guide.svg';
import { ReactComponent as HomesIcon } from './../../assets/mobileMenu/homes.svg';
import { ReactComponent as ProfileIcon } from './../../assets/mobileMenu/profile.svg';
import { ReactComponent as SubscriptionIcon } from './../../assets/mobileMenu/subscription.svg';
import styles from './mobileMenu.module.scss';

import { Row } from "@/components/Layout";
import { useLaptop, useLaptopMini, useMobile, useMobileMini } from "@/hooks/useAdaptives";

const MobileMenu = ({ className = '' }) => {

    const isLaptop = useLaptop();
    const isLaptopMini = useLaptopMini();
    const isMobile = useMobile();
    const isMobileMini = useMobileMini();

    return (
        <nav className={`${styles.menu} ${className}`} style={{ display: isLaptop || isLaptopMini || isMobile || isMobileMini ? 'block' : 'none' }}>
            <Row className={`${styles.menu_cent} flex-nowrap gap-1`}>
                {/* <NavLink className={({isActive}) => isActive ? `${styles.link_wrapper} ${styles.active}` : `${styles.link_wrapper}`}
                    to={'/dashboard'}>
                    <DashboardIcon className={styles.icon}/>
                    <p>Dashboard</p>
                </NavLink> */}
                <NavLink className={({ isActive }) => isActive ? `${styles.link_wrapper} ${styles.homes} ${styles.active}` : `${styles.link_wrapper} ${styles.homes}`}
                    to={'/my-properties'} >
                    <HomesIcon className={styles.icon} />
                    <p>Homes</p>
                </NavLink>
                <NavLink className={({ isActive }) => isActive ? `${styles.link_wrapper} ${styles.guide} ${styles.active}` : `${styles.link_wrapper} ${styles.guide}`}
                    to={'/comparison'} >
                    <GuideIcon className={styles.icon} />
                    <p>Guide</p>
                </NavLink>
                {/*<NavLink className={({ isActive }) => isActive ? `${styles.link_wrapper} ${styles.active}` : `${styles.link_wrapper}`}*/}
                {/*         to={'/subscriptions'} >*/}
                {/*    <SubscriptionIcon className={styles.icon} />*/}
                {/*    <p>Subscription</p>*/}
                {/*</NavLink>*/}
                <NavLink className={({ isActive }) => isActive ? `${styles.link_wrapper} ${styles.active}` : `${styles.link_wrapper}`}
                    to={'/profile'} >
                    <ProfileIcon className={styles.icon} />
                    <p>Profile</p>
                </NavLink>
            </Row>
        </nav>
    )
}

MobileMenu.propTypes = {
    className: PropTypes.string,
};
export default MobileMenu;