import {Dialog} from "@headlessui/react";
import {Drawer} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";

import styles from './layout.module.scss';

import xThin from "@/assets/icons/xThin.svg";
import Header from '@/components/Header';
import MobileMenu from "@/components/mobileMenu/mobileMenu";
import Sidebar from "@/components/Sidebar/sidebar";
import { ColorAccent } from "@/constants";
import { useDesktop, useLaptop, useLaptopMini, useMobile, useMobileMini } from "@/hooks/useAdaptives";
import { wrapper } from '@/utils';


const DOCS_PRIVACY_URL = process.env.REACT_APP_DOCS_PRIVACY_URL;
const DOCS_TERMS_URL = process.env.REACT_APP_DOCS_TERMS_URL;

export const Contents = wrapper(({ children, className = '', ...props }) => (
    <div className={styles.mainContents + ' ' + className} {...props}>
        {children}
    </div>
));

export const Layout = wrapper(({ children, className = '', mainAddClass = '', ...props }) => {
    const isLaptop = useLaptop();
    const isLaptopMini = useLaptopMini();
    const isMobile = useMobile();
    const isMobileMini = useMobileMini();

    return (
        <div className={styles.layoutBox + ' ' + className} {...props}
        // style={{paddingBottom: isLaptop || isLaptopMini || isMobile || isMobileMini ? '101px' : ''}}
        >
            <Header/>
            <main className={styles.main + ' ' + mainAddClass} {...props}>
                {children}
            </main>
            {/*<MobileMenu/>*/}
        </div>
    )
});

export const MobileLayout = wrapper(({ children, className = '', mainAddClass = '', ...props }) => {

    return (
        <div className={styles.mobileLayoutBox + ' ' + className} {...props}>
            {/*<Drawer open={true}*/}
            {/*        //onClose={toggleDrawer(false)}*/}
            {/*      //  style={{visibility: showSidebar ? 'visible' : 'hidden'}}*/}
            {/*>*/}
            {/*    <Sidebar/>*/}
            {/*</Drawer>*/}
            <main className={styles.mobile_main + ' ' + mainAddClass}>
                {children}
            </main>
            <MobileMenu />
        </div>
    )
});

export const Flex = wrapper(({ children, className = '', ...props }) => (
    <div className={styles.flexContainer + ' ' + className} {...props}>
        {children}
    </div>
));

export const Row = wrapper(({ children, className = '', ...props }) => (
    <div className={styles.row + ' ' + className} {...props}>
        {children}
    </div>
));

export const Col = wrapper(({ children, className = '', ...props }) => (
    <div className={styles.col + ' ' + className} {...props}>
        {children}
    </div>
));

export const Button = wrapper(({ children, className = '', colorAccent = ColorAccent.black, ...props }) => (
    <button className={`${styles.btn} ${className} accent-${colorAccent}`} {...props}>
        {children}
    </button>
))

export const Icon = wrapper(({ src, className = '', style = {}, size = null, ...props }) => (
    <img
        src={src}
        className={className + ' ' + 'icon'}
        {...props}
        style={{ height: '1em', minWidth: '1em', fontSize: size || undefined, ...style }}
    />
))

export const Input = wrapper(({ className = '', onChange = null, ...props }) => {
    const onChangeWrapper = onChange ? e => onChange(e.target.value, e) : undefined;

    return <input {...props} className={className} onChange={onChangeWrapper} />;
})

export const Modal = wrapper(({ isOpen = true,
    onClose = () => { },
    children, className = '',
    panelClassName = '',
    title = null,
    titleClassName = '',
    text = "", ...props }) => {

    return (
        <Dialog as="div" className={styles.dialog + ' ' + className} open={isOpen} onClose={onClose}>
            <Dialog.Panel className={styles.panel + ' ' + panelClassName}>
                {text !== "" && <>{text}</>}
                {title && <Dialog.Title className={styles.title + ' ' + titleClassName}>{title}</Dialog.Title>}
                <Button colorAccent={ColorAccent.white} className={styles.closeBtn} onClick={onClose}>
                    <Icon src={xThin} size={24}/>
                </Button>
                {children}
            </Dialog.Panel>
        </Dialog>
    )
})

export const Img = wrapper(({ src, className = '', preloadSrc = null, errorSrc = null, ...props }) => {
    const [imgSrc, setImgSrc] = useState(preloadSrc);
    const [imgState, setImgState] = useState('loading');

    const styles = {};

    const onLoad = useCallback(() => {
        setImgSrc(src);
        setImgState('loaded');
    }, [src]);

    const onError = useCallback(() => {
        errorSrc && setImgSrc(errorSrc);
        setImgState('failed');
    }, []);

    useEffect(() => {
        const img = new Image();
        img.onload = onLoad;
        img.onerror = onError;
        img.src = src;
    }, [src]);


    return <img src={imgSrc} className={styles[imgState] + ' ' + className} {...props} />;
})