import PropTypes from "prop-types";
import React, {useState} from "react";

import styles from './homeSearh.module.scss';
import common_styles from './onboarding.module.scss';

import filters from '@/assets/onboarding/homeSearch/filters.png';
import kanban_card from '@/assets/onboarding/homeSearch/kanban_card.png';
import people from '@/assets/onboarding/homeSearch/people.png';
import {Button} from "@/components/Layout";
import {ColorAccent} from "@/constants";
import {useWindowHeight} from "@/hooks/customHooks";




const HomeSearch = ({
                        onClose = () => {
                        },
                        onNext = () => {
                        }
                    }) => {

    const windowHeight = useWindowHeight();


    return(
        <div className={styles.main_wrapper}>
            <div className={common_styles.content_wrapper}>
                <div className={styles.right_images_wrapper}>
                    <img src={filters} alt=""/>
                    <img src={kanban_card} alt=""/>
                </div>

                <img src={people} alt="" className={styles.people}/>

               <div className={`${common_styles.info_card} ${windowHeight < 730 ? common_styles.small : ''}`}>
                   <div className={common_styles.title}>Organize your <br/> home search</div>
                   <div className={common_styles.description}>Add and sort properties you’re considering in one place. No more chaos!</div>
                   <Button colorAccent={ColorAccent.black} className={'w-100 py-3 mb-3'} onClick={onNext}>
                       Next
                   </Button>
                   <div className={common_styles.skip} onClick={onClose}>Skip for now</div>
               </div>
            </div>
        </div>
    )
}

HomeSearch.propTypes = {
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
}
export default HomeSearch;