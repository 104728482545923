import { CheckFat } from "@phosphor-icons/react";
import { track } from "@vercel/analytics/react";
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import styles from './KanbanScreen.module.scss';

import { ReportAPI, KanbanState } from "@/api/report";
import { StripeAPI, SubscriptionPlans } from "@/api/stripe";
import { UserAPI } from "@/api/user";
import stripe_img from '@/assets/custom-stripe.png';
import arrow_add from '@/assets/kanbanScreen/arrow_add.svg';
import search from '@/assets/kanbanScreen/search.png';
import CustomMuiDialogWindow from "@/components/customMuiDialogWindow/customMuiDialogWindow";
import { Demo } from "@/components/Demo";
import { Button, Col, Contents, Icon, Modal, Row, MobileLayout, Layout as MainLayout } from "@/components/Layout";
import MobileTopBar from "@/components/mobileTopBar/mobileTopBar";
import { Survey } from "@/components/v1Survey";
import { WarnUpload } from "@/components/WarnUpload";
import { ColorAccent } from "@/constants";
import { useDesktop, useDesktopMini, useMobile, useMobileMini } from "@/hooks/useAdaptives";
import { useLocationHistory } from "@/previousLocationContext";
import { dispatch } from "@/store";
import { getAllReports, getReports, setDemo, setOnboarding, setWarn } from "@/store/reports/reportsSlice";
import { getSubInfo } from "@/store/stripe/stripeSlice";
import MainScreen from "@/views/KanbanScreen/MainScreen/MainScreen";
import MobileScreen from "@/views/KanbanScreen/MobileScreen/MobileScreen";
import Onboarding from "@/views/Onboarding/onboarding";
import SelectPlan from "@/views/SelectPlan/selectPlan";


const DOCS_PRIVACY_URL = process.env.REACT_APP_DOCS_PRIVACY_URL;
const DOCS_TERMS_URL = process.env.REACT_APP_DOCS_TERMS_URL;
const MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const KanbanScreen = ({ className = '', ...props }) => {
    const { reports, demo, warn, onboarding } = useSelector(state => state.reports);
    const [filteredReports, setFilteredReports] = useState([]);
    const [chosenLocation, setChosenLocation] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [fromRegister, setFromRegister] = useState(null)
    // const [userPreferences, setUserPreferences] = useState(null);
    const isMobile = useMobile();
    const isMobileMini = useMobileMini();
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();

    const reportsWithoutSample = reports.filter(r => r.name !== 'Sample Report');

    const [subscriptionsInfo, getSubscriptionsInfo] = useState(null);

    useEffect(() => {
        if (localStorage.getItem('register')) {
            setFromRegister(true);
        } else {
            setFromRegister(false);
        }
    }, [])

    const { remaining } = useSelector((state) => state.stripe);

    useEffect(() => {
        dispatch(getSubInfo());
    }, [dispatch]); // Dispatch only needs to be run once on component mount

    // Log `remaining` whenever it changes
    useEffect(() => {
        console.log('Remaining:', remaining);
    }, [remaining]);

    useEffect(() => {
        const preProcessed = JSON.parse(localStorage.getItem('pre-process'))
        if (preProcessed && preProcessed?.address && remaining === 0) {
            localStorage.removeItem('pre-process');
            console.log("removed")
        } else if (preProcessed && (remaining === 'Unlimited' || remaining > preProcessed?.remaining)) {
            toast.success(remaining === "Unlimited" ? "You now have unlimited additions with Launch Offer!" : "You can now add 5 more homes!")
            if (preProcessed?.address && preProcessed?.address !== '') {
                setIsLoading(true)
                ReportAPI.uploadNew(preProcessed?.address).then(data => {
                    ref.current.value = '';
                    setChosenLocation(null);
                    toast.success("Added home you wanted to view!");
                    setIsLoading(false);
                    dispatch(getAllReports({ checkWarn: true }));
                    if (remaining !== 'Unlimited') {
                        dispatch(getSubInfo());
                    }
                }).catch(err => setIsLoading(false));
            }
            localStorage.removeItem('pre-process');
            track("Purchase made")
        }
    }, [subscriptionsInfo])

    const preSaveAddress = () => {
        const preProcess = {
            address: chosenLocation,
            remaining: remaining
        }
        localStorage.setItem('pre-process', JSON.stringify(preProcess))
    }

    //for plan select
    const [isPlanOpen, setIsPlanOpen] = useState(false);

    const onClosePlanModalWindow = () => {
        setIsPlanOpen(false)
    }

    const locationHistory = useLocationHistory();

    const previousLocation = locationHistory.length >= 2 ? locationHistory[locationHistory.length - 2] : null;
    const twoStepsBackLocation = locationHistory.length >= 3 ? locationHistory[locationHistory.length - 3] : null;

    const [activeTab, setActiveTab] = useState({ id: 1, title: KanbanState.CONSIDERING, state: KanbanState.CONSIDERING });

    const filterTabs = [
        { id: 1, title: KanbanState.CONSIDERING, state: KanbanState.CONSIDERING },
        { id: 2, title: KanbanState.VIEWED, state: KanbanState.VIEWED },
        { id: 3, title: KanbanState.AWAITING_DETAILS, state: KanbanState.AWAITING_DETAILS },
        { id: 4, title: KanbanState.SHORTLISTED, state: KanbanState.SHORTLISTED },
    ]

    const { ref, autocompleteRef } = usePlacesWidget({
        apiKey: MAPS_API_KEY,
        onPlaceSelected: (place) => {
            place?.formatted_address && setChosenLocation(place.formatted_address)
        },
        options: {
            types: ['address'],
            componentRestrictions: { country: 'us' },
        },
    });

    const createNewReport = e => {
        e.preventDefault();
        if ((remaining === 0) && chosenLocation) {
            setIsPlanOpen(true);
            console.log(chosenLocation)
        } else if (chosenLocation && !isLoading) {
            setIsLoading(true)
            ReportAPI.uploadNew(chosenLocation)
                .then(data => {
                    ref.current.value = '';
                    setChosenLocation(null);
                    toast.success("Added a new home!");
                    setIsLoading(false);
                    dispatch(getAllReports({ checkWarn: true }));
                    if (remaining !== 'Unlimited') {
                        dispatch(getSubInfo());
                    }
                    setChosenLocation(null);
                })
                .catch(err => setIsLoading(false));
        }
    };

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            createNewReport(event);
        }
    }

    //for filtering all reports
    useEffect(() => {
        let newFilteredReports = reports && reports?.filter(r => r.state === activeTab.state);
        newFilteredReports && setFilteredReports(newFilteredReports);
    }, [activeTab, reports])


    //get all reports from server
    useEffect(() => {
        dispatch(getAllReports({ firstLoading: true }));

        return () => {
            dispatch(getReports([]))
        }
    }, [])


    // useEffect(() => {
    //     UserAPI.getUserPreferences().then(data => {
    //         setUserPreferences(data.length);
    //     })
    // }, [])


    const planDialogStyles = {
        // backgroundColor: 'inherit',
        backgroundColor: '#cbccc7',
        width: '100%',
        maxWidth: '800px',
        margin: '7px',
        borderRadius: '25px',
        //  border: '3px solid green'
    }

    const planDialogStyleForMobiles = {
        // backgroundColor: 'white',
        // width: '100vw',
        // maxWidth: '100vw',
        // minHeight: '100vh',
        // margin: '0',
        // borderRadius: '0',
    }

    const planDialogContentStyles = {
        textAlign: 'center',
        padding: '0',
    }


    const Layout = !isDesktop && !isDesktopMini ? MobileLayout : MainLayout;

    //check user subscriptions for stripe
    useEffect(() => {
        StripeAPI.checkPayment().then(res => {
            getSubscriptionsInfo(res);
        })
    }, [])

    // console.log(subscriptionsInfo);

    const onCloseOnboardingModalWindow = (event, reason) => {
        localStorage.removeItem("register");
        if (reason === 'backdropClick') {
            return;
        }
        dispatch(setOnboarding({ onboarding: false }))
    }

    const onboardingDialogStyles = {
        backgroundColor: 'inherit',
        width: '100%',
        maxWidth: '470px',
        height: '100%',
        maxHeight: '837px',
        borderRadius: '40px',
    }

    const onboardingDialogStyleForMobiles = {
        backgroundColor: 'inherit',
        width: '100vw',
        height: '100%',
        minHeight: '100vh',
        maxHeight: '100vh',
        margin: '0',
        borderRadius: '0',
    }

    const onboardingDialogContentStyles = {
        textAlign: 'center',
        padding: '0',
    }

    return (
        <Layout className={styles.layout}
            mainAddClass={!isDesktop && !isDesktopMini ? styles.mobile_main : styles.desktop_main}>
            {!isDesktop && !isDesktopMini ? <MobileTopBar title={''} /> : null}
            <Contents className={'d-flex'}>
                <Col className={'flex-grow-1 w-100'}>
                    <div className={`${styles.search_and_filter_wrapper} w-100`}>
                        <Row className={`${styles.search_block} w-100 flex-nowrap`}>
                            <Row className={`w-100 flex-nowrap justify-content-start gap-2 ${styles.addressInputBar}`}>
                                <Icon src={search} style={{ width: '15px', height: '15px', minWidth: '15px' }} />
                                <input
                                    onKeyDown={handleKeyDown}
                                    ref={ref}
                                    placeholder={'Add an address'}
                                    className={`d-block w-100 ${styles.input}`}
                                    style={{ opacity: isLoading ? '.5' : '1', }}
                                    disabled={isLoading}
                                />
                            </Row>
                            <Button
                                className={`${styles.add_btn} p-0 border-0 flex-shrink-0`}
                                style={chosenLocation ? {} : { backgroundColor: 'gray' }}
                                onClick={createNewReport}>
                                <Icon src={arrow_add}
                                    style={{ width: '1.5rem', height: '1.375rem', minWidth: '1.5rem' }} />
                            </Button>
                        </Row>
                        {!isDesktop && !isDesktopMini ? <div className={'overflow-hidden'}>
                            <div className={`d-flex flex-nowrap gap-1 ${styles.filters_block}`}>
                                {
                                    filterTabs.map(tab => <div
                                        className={`${styles.filter_tab} ${activeTab.id === tab.id ? styles.active_tab : ''}`}
                                        key={tab.id} onClick={() => setActiveTab(tab)}>{tab.title}</div>)
                                }
                            </div>
                        </div> : null}
                    </div>

                    <div className={styles.main_context_wrapper}>
                        {
                            isLoading ?
                                <div className="spinner-border text-dark" style={{ width: '28px', height: '28px' }}
                                    role="status"></div>
                                :
                                <>
                                    <Row className={'justify-content-end mb-3'}>
                                        {/* <Demo isOpen={demo && previousLocation?.pathname === '/' && twoStepsBackLocation?.pathname === '/login'}
                                            onClose={() => {
                                                dispatch(setDemo({ demo: false }))
                                            }}
                                            onNext={() => {
                                                dispatch(setDemo({ demo: false }))
                                            }} /> */}
                                        {/*{typeof userPreferences === 'number' && userPreferences < 8 ?*/}
                                        {/*    <Survey title={userPreferences < 8 ? <>Take our Buyer Preference*/}
                                        {/*        Quiz</> : <>Quiz*/}
                                        {/*        completed <CheckFat color={'green'} weight={'fill'}*/}
                                        {/*                            size={'1.5rem'}/></>}/>*/}
                                        {/*    : null*/}
                                        {/*}*/}
                                    </Row>
                                    {
                                        !isDesktop && !isDesktopMini ?
                                            <MobileScreen reports={filteredReports} activeTab={activeTab}
                                                setActiveTab={setActiveTab} />
                                            : <MainScreen />
                                    }
                                </>
                        }
                    </div>

                    <Row className={`${styles.footer} mt-auto py-3 w-100 gap-1 justify-content-center border-top py-2`}>
                        <span>©2024 Homescore&nbsp; </span> | <Link to={DOCS_TERMS_URL}>&nbsp; Terms of
                            Service&nbsp; </Link> |
                        <Link to={DOCS_PRIVACY_URL}>&nbsp; Privacy Policy</Link>
                    </Row>
                </Col>

                <WarnUpload isOpen={warn}
                    onClose={() => (dispatch(setWarn({ warn: false })))}
                    onNext={() => (dispatch(setWarn({ warn: false })))} />

                <CustomMuiDialogWindow
                    open={onboarding && ((previousLocation?.pathname === '/' && twoStepsBackLocation?.pathname === '/register') || fromRegister)}
                    onClose={onCloseOnboardingModalWindow}
                    dialogStyles={isMobileMini ? onboardingDialogStyleForMobiles : onboardingDialogStyles}
                    dialogContentStyles={onboardingDialogContentStyles}
                // backdrop={{ backgroundColor: 'rgba(0,0,0,0.9)'}}
                >
                    <Onboarding onClose={onCloseOnboardingModalWindow} />
                </CustomMuiDialogWindow>


                <CustomMuiDialogWindow open={isPlanOpen}
                    onClose={onClosePlanModalWindow}
                    dialogStyles={planDialogStyles}
                    dialogContentStyles={planDialogContentStyles}>
                    {/*<SelectPlan onClose={onClosePlanModalWindow}/>*/}
                    <div className={styles.subscription_link_modal}>
                        <div className={styles.close_btn} onClick={onClosePlanModalWindow}></div>
                        <p className={`${styles.title} mt-5`} style={{ fontSize: '30px' }}>Time to Upgrade</p>
                        <img src={stripe_img} alt="" style={{ width: '15rem' }} className={'d-block mx-auto my-5'} />
                        <Link to={'/subscriptions'} className={'d-block mx-auto cursor-pointer'}>
                            <Button onClick={() => { preSaveAddress() }} colorAccent={ColorAccent.black} style={{ width: '70%', fontSize: '16px' }}
                                className={'py-3 mt-3 mx-auto'}>
                                Purchase
                            </Button>
                        </Link>
                    </div>
                </CustomMuiDialogWindow>

            </Contents>
        </Layout>
    )
}

KanbanScreen.propTypes = {
    className: PropTypes.string,
};

export default KanbanScreen;